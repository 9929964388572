.time-picker-container-session {
    margin-left: 20px;
    width: fit-content;
    height: min-content;
    padding: 0.33em 0.8em;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
}

.time-picker-container-session:hover {
    border: 1px solid black
}

.time-picker-section-session {
    display: flex;
    justify-content: center;
    align-items: center;
}

.time-picker-header-session {
    display: none;
}

.time-picker-section-session span {
    margin: 0 5px;
}

.time-picker-container {
    width: 100%;
    max-width: 258.4px;
    /* background: #f0f0f0; */
    border: 1px solid rgb(195, 195, 195);
    padding: 15px 10px 10px 15px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin-bottom: 10px;
    cursor: text;
}

.time-picker-container:hover {
    border: 1px solid rgb(67, 67, 67);
}

.time-picker-header {
    margin-bottom: 10px;
    font-size: 0.9em;
    color: gray;
    font-weight: normal;
    text-align: start;
    /* margin-left: 20px; */
}

.time-picker-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.time-picker-input {
    border: 1px solid gainsboro;
    color: #0952B3;
    padding: 10px 5px;
    width: 40px;
    text-align: center;
    font-size: 20px;
    border-radius: 4px;
    background: transparent;
}

.time-picker-input:focus {
    border: 1px solid #0952B3;
}

.time-picker-input::placeholder {
    font-size: 0.8em;
}

.time-picker-input:invalid {
    border: 1px solid red;
}

.time-picker-section span {
    font-size: 32px;
    margin: 0 10px;
}

.time-picker-meridian {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
}

.time-picker-meridian-button {
    border: 1px solid gainsboro;
    font-size: 0.8em;
    padding: 5px 12px 1px 12px;
    border-radius: 4px;
    background: transparent;
}

.time-picker-meridian-button:first-child {
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.time-picker-meridian-button:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.time-picker-meridian-button-active {
    background-color: whitesmoke;
    color: #0952B3;
}

.time-picker-button {
    display: flex;
    justify-content: flex-end;
}

.time-picker-close,
.time-picker-ok {
    border: 1px solid gray;
    background: #fff;
    padding: 6px 8px;
    font-size: 0.8em;
    border-radius: 4px;
    margin-right: 10px;
}

.time-picker-ok {
    background-color: #0952B3;
    color: #fff;
    margin-right: 28px;
}


.session-time-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 0.9em;
}


.time-picker-input:disabled,
.time-picker-meridian-button:disabled {
    color: #BDBDBD;
}

.time-picker-meridian-button-active:disabled {
    color: white;
}



/* DATE COMPONENT CSS */
.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    position: relative;
    cursor: pointer;
}
.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel:hover > .mui-day-label {
    opacity: 1;
}

.mui-day-label, .mui-unavailable-label {
    opacity: 0;
    position: absolute;
    top: -10px;
    background: #0952B3;
    color: white;
    padding: 0em 0.4em;
    border-radius: 0.6em;
    transition: 0.3s;
    pointer-events: none;
}

.mui-unavailable-day:not(.Mui-disabled):not(.Mui-selected) {
    background: rgba(255, 166, 0, 0.408) !important;
}

.mui-unavailable-label {
    top: -2em;
    background: rgb(255, 166, 0) !important;
}
.MuiDayCalendar-weekContainer button:hover span {
    opacity: 1;
}