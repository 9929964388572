/* CSS for prof.css */

.prof-container1 .rating {
    text-align: center;
    font-size: 0.9em;
    margin: 1em;
}

.prof-container1 .profile-image {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  width: 195px;
  height: 195px;
  border: 5px solid #0857C0;
  border-radius: 100%;
  margin-top: 2em;
}

.prof-container1 .profile-image img {
  aspect-ratio: 1/1;
  height: 189px;
  width: 189px;
  border: 5px solid #ffffff;
  border-radius: 100%;
  object-fit: cover;
  cursor: pointer;
}

.eye-icon {
  width: 0.9em;
}

.joined-active-recommended {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* height: 100px; */
  text-align: center;
}

.joined-active-recommended .link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gigs-content>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.gigs-content>div>h2 {
  align-self: center;
  margin: 20px;
}

.gigs-content .column {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}



.gigs-content .GigThumbnail {
  width: 100%;
  height: auto;
  aspect-ratio: 16/11;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 8px #1b1b1b1a;

}

.gigs-content .GigThumbnail h2 {
  font-size: 1.6em;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.gigs-content .GigThumbnail .gig-thumbnail-right {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gigs-content .GigThumbnail .gig-thumbnail-right .edit-link {
  display: flex;
  justify-content: center;
}

.gigs-content .GigThumbnail .gig-thumbnail-right .edit-link button {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 0;
  background-color: #0952b3;

}

.gigs-content .GigThumbnail .gig-thumbnail-left>span>span {
  height: 0;
}

.gigs-content .GigThumbnail .gig-thumbnail-left>span {
  padding: 0;
}

.gigs-content .GigThumbnail img {
  padding: 10px;
  border-radius: inherit;
  height: auto;
}

.GigThumbnail .MuiRating-root * {
  padding: 0 !important;
}




.profile-image {
  aspect-ratio: 1/1;
  height: 192px;
  border: 5px solid #0857C0;
  border-radius: 50%;

}

.profile-image img {
  height: 189px;
  aspect-ratio: 1/1;
  border: 2px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.upload-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.username {
  margin-top: 20px;
  text-align: center;
}

.username p {
  font-weight: 500;
  font-size: 22px;
}

.ent-cons-ref {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.25em;
  margin-top: -15px;
}

.ent-cons-ref p {
  transition: 0.2s;
  padding-bottom: 2px;
}

.ent-cons-ref:hover p {
  box-shadow: 0px 1px 0px 0px black;
}

.ent-cons-ref img {
  border-radius: 100%;
  margin-right: 5px;
  object-fit: cover;
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.description h3 {
  margin-bottom: 10px;
}

.description p {
  font-weight: 300;
  font-size: 16px;
}

.desc {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.description-icon {
  transition: 0.3s;
}
.description-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.consultant-description {
  background: white;
}

.consultant-description-rendered * {
  all: revert !important;
}
.consultant-description-rendered {
  background: white;
  padding: 1em;
}

.ql-editor {
  padding: 1em;
}
.ql-editor * {
  all: revert !important;
}

.consultant-booking-button {
  background: var(--blue-default);
  color: whitesmoke;
  padding: 1em;
  text-align: center;
  transition: 0.3s;
  border-radius: 0.8em;
  box-shadow: var(--std-shadow);
}
.consultant-booking-button:hover {
  transform: scale(1.03);
}


.desc img {
  height: 17px;
  width: 17px;
}

.recommendations {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.recommendations h3 {
  margin-bottom: 10px;
}

.recommendation-container {
  padding: 20px 0;
  width: 100%;


}

.get-in-touch {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* position: absolute;
  top: 20px;
  right: 20px; */
  text-decoration: none;
}

.gigs-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0% 0 0 0;
  width: 80%;
}

.gigs-list>a {
  display: flex;
  /* justify-self: center; */
  /* align-items: center; */
  width: 95%;
  min-width: 260px;
  /* max-width: 400px; */
}  

.post {
  margin-left: 100%;
  padding: 20px;
  background-color: #fff;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-icon {
  border-radius: 50%;
  background-color: #D9D9D9;
  height: 86px;
  width: 86px;
  padding: 21px 21px;
}

.post-form img {
  height: 45.5px;
  width: 45.5px;
}

.post-form p {
  padding: 20px;
  font-weight: 400;
  font-size: 22px;
}

.row {
  display: flex;
  flex-direction: row;
}



.cover-image img {
  width: 100%;
  aspect-ratio: 16/9;
}


.contents {
  width: 100%;
}

.gigs-list {
  width: 98%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-row-gap: 0.7em;
  grid-column-gap: 0.7em;
  /* margin: 0 auto; */
}

.review-title {
  margin: 1.4em;
  width: 100%;
  text-align: left;

  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.summary-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.summary-section {
  padding: 1.5em 0;
  /* border: 1px solid black; */
  border-radius: 8px;
  /* background-color: #007bff; */
}

.total-revenue-span {
  font-weight: 800;
  font-size: 1.2em;
}

.revenue-span {
  font-size: 0.8em;
  font-weight: 800;
  color: green;
}

.header {
  color: red;
  font-size: 1.2em;
  padding-bottom: 0.5em;
}


.summary-section-bubble {
  color: white;
  padding: 1.5em 4em 1em 2em;
  border-radius: 8px;
  background: rgb(2, 0, 36);
  background: linear-gradient(18deg, rgba(2, 0, 36, 1) 0%, rgba(51, 51, 173, 1) 0%, rgba(0, 212, 255, 1) 85%);
}

.bubble-header {
  font-size: 1.5em;
  padding-bottom: .3em;
  font-weight: normal;
}

.bubble-span {
  font-weight: 600;
}

/* REVIEW BLOCK */
.review-block {
  display: flex;
  align-self: center;
  padding: 1em 0;
  margin: 0;
  width: 100%;
  margin-top: -1px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  justify-content: space-between;
}

.review-block>img {
  display: block;
  height: 50px;
  border-radius: 100%;
  aspect-ratio: 1/1;
  margin: 0 10px 0 0;
  /* object-fit: cover; */
}

.review-block>div {
  flex: 1;
}

.review-block>div>span>span svg {
  width: 0.6em;
}

.review-block>div>img {
  width: 100px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}

.review-block>div>p {
  font-size: 0.8em;
}

.review-block .review-name {
  font-size: 0.8em;
  font-weight: 500;
}

.empty-session-container {
  margin: auto;
  text-align: center;
}

@media(max-width: 1280px) {
  .prof-container1 {
    width: 100%;
  }

  .prof-container1 .profile-image {
    width: 150px;
    height: 150px;
  }

  .prof-container1 .profile-image img {
    width: 140px;
    height: 140px;
  }

  .username {
    font-size: 1em;
  }

  .joined-active-recommended .link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .joined-active-recommended .link .ent-cons-ref {
    font-size: 1.15em;
    text-align: center;
  }

  .description .desc h3 {
    font-size: 1em;
  }

  .description p {
    text-align: left;
    align-self: flex-start;
  }

  /* .gigs-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  } */

  .gigs-column {
    width: 300px !important;
  }

  .gigs-list>a {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 90%;
    min-width: 300px;

  }





}

@media(max-width: 768px) {
  .row {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 0;
  }

  .prof-container1 {
    width: 90%;
  }

  .gigs-content {
    margin: 40px 0;
    width: 100%;
  }

  /* 
  .gigs-list{
    display: flex !important;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    margin: 0;
  } */

  .gigs-list>a {
    width: min(80%, 450px);
    justify-content: center;
    max-width: none;
  }

  .gigs-list .GigThumbnail {
    width: 100%;
  }

  .col {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;
  }
}



.consultant-offline-status, .consultant-online-status {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em 0.8em;
  margin: 1em;
  border-radius: 1.2em;
  border: 2px solid;
  font-size: 0.8em;
  font-weight: bold;  
}
.consultant-offline-status::before, .consultant-online-status::before {
  content: '';
  display: inline-block;
  width: 0.5em;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: black;
  margin-right: 0.3em;
}


:root {
  --yellow: #faaf00;
  --green: hsl(122, 100%, 34%);
}
.consultant-offline-status {
  border-color: var(--yellow);
  color: var(--yellow);
  background: rgba(250, 175, 0, 0.1);
}
.consultant-offline-status:before {
  background: var(--yellow);
}

.consultant-online-status {
  border-color: var(--green);
  color: var(--green);
  background: rgba(0, 173, 6, 0.1);
}
.consultant-online-status:before {
  background: var(--green);
}
