.preloader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
    animation: heartbeat 1s ease-in-out infinite;
}

.loader {
    width: 70px;
    border-radius: 50%;
    aspect-ratio: 1;
    background: white;
    box-shadow: 0 0 0 10px white;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}

.progress-circle {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: -1;
    background: conic-gradient(#0952b3 50%, transparent 50%);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}