.progresscheck-step {
    padding: 0.8em 1em;
}

.progresscheck-step-header {
    border-radius: 8px;
    background: #0857C0;
    /* width: 449px; */
    height: 30px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0.4em 0.6em;
}

.progresscheck-step-count {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0.5em 1em;
}

@media screen and (max-width: 769px) {
    .progresscheck-step-header {
        width: 100%;
    }

}