.countdown-timer-container {
    display: flex;
    align-items: baseline;
    font-size: 1em;
    width: 100%;
    height: 100%;
    transition: 0.3s;
}

.count-down-timer {
    display: flex;
    width: 100%;
    padding: 0.7em 1em;
    align-items: center;
}

.count-down-timer-timer > * {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    color: #0952B3 !important;
    font-weight: bold !important;
}

.timer-clock-icon {
    font-size: 1.6em;
}
    
.count-down-timer-timer p:last-child {
    font-size: 0.8em !important;
}

.count-down-timer-timer {
    display: flex;
    justify-content: left;
    align-items: baseline;
    width: 100%;
    height: 100%;
}

.countdown-timer-container span {
    background: #0952B3;
    color: whitesmoke;
    padding: 0.7em 1em;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0 0 1em 1em;
}
.countdown-timer-container span:hover {
    background: #EDB506;
    color: whitesmoke;
}