.user-categories-selector {
    margin: 0 var(--margin);
}

.user-categories-selector-container {
    display: flex;
    flex-wrap: wrap;
}

.user-category-selector {
    display: flex;
    align-items: center;
    background: #0952b3;
    color: whitesmoke;
    border-radius: 1em;
    padding: 0.2em 1em;
    margin: 0.2em;
    cursor: pointer;
    transition: 0.3s;
}

/* .user-category-selector:hover {
    background: #004fab;
    transform: scale(1.03);
} */

.user-category-selector.selected-category {
    background: rgb(154, 154, 154);
    color: white;
}

.user-categories-limit-height {
    max-height: 6em;
    overflow: hidden;
}

.user-categories-selector-container {
    position: relative;
    /* background: white; */
}

.user-categories-selector-more-option {
    position: absolute;
    bottom: 0;
    right: 0;
    color: gray;
    background: inherit;
}

.user-categories-selector-more-option p {
    display: inline-block;
    background: inherit;
    padding: 0.2em 1em;
    margin: 0.2em;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;
}

.user-categories-selector-more-option p:hover {
    transform: scale(1.03);
}

.user-categories-selector-more-option::before {
    content: '';
    display: inline-block;
    position: absolute;
    right: 100%;
    width: 200px;
    height: 1.8em;
    background: linear-gradient(to right, #fff0, #ffff);
}

.user-category-delete {
    background: #5189d2;
    border-radius: 50%;
    margin-left: 1em;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.3em;
    height: 1.3em;
    transition: 0.3s;
}
.user-category-delete:hover {
    transform: scale(1.03);
    background: red;
}


.user-goals-input {
    display: inline-block;
    position: relative;
    background: #0952b3;
    color: whitesmoke;
    border-radius: 1em;
    padding: 0.2em 1em;
    margin: 0.2em;
    cursor: pointer;
    transition: 0.3s;
}
.user-goals-options {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--gray-background);
    width: 100%;
    border-radius: 0.6em;
}
.user-goals-option {
    color: black;
    padding: 0.2em 1em;
    transition: 0.3s;
}
.user-goals-option:hover {
    color: black;
    background: rgb(179, 179, 179);
    padding: 0.2em 1em;
}
.user-goals-input-field {
    display: flex;
    transition: 1s;
}
.user-goals-input-field input {
    background: transparent;
    color: whitesmoke;
    outline: none;
    border: none;
}
.user-goals-input-field button {
    background: #5189d2;
    border-radius: 50%;
    margin-left: 1em;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    width: 1.3em;
    height: 1.3em;
    transition: 0.3s;
    padding: 0;
}
.user-goals-suggessions {
    display: flex;
    flex-wrap: wrap;
}
.user-goals-suggession {
    display: flex;
    align-items: center;
    background: gray;
    color: whitesmoke;
    border-radius: 1em;
    padding: 0.2em 1em;
    margin: 0.2em;
    cursor: pointer;
    text-wrap: nowrap;
    transition: 0.3s;
}
.user-goals-disabled {
    background: rgb(181, 181, 181);
    cursor: not-allowed;
}
.user-goals-suggession:not(.user-goals-disabled):hover {
    background: rgb(80, 80, 80);
}
.recommended-gig-header2 {
    margin: 2em 0 1em 0;
    font-size: 1.3em;
}