.gig-setup-page-wrapper {
    box-sizing: border-box;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    gap: 1em;
    overflow: hidden;
    border-radius: 0px 0px 2px 2px;
    background: #FCFCFC;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 2em;
}

.gig-setup-page-progress {
    width: 50%;
    min-width: 300px;
}

.gig-setup-page-step-container {
    width: 60%;
}

.pageButtons {
    display: flex;
    position: sticky;
    bottom: 0;
    margin-top: 0.5em;
    padding: 15px 35px 15px 35px;
    /* padding-left: 0; */
    justify-content: space-between;
    background: #f9f9f9;
}

.pageButtons button {
    background: #0952b3;
    color: #f9f9f9;
    border-radius: 0.4em;
    transition: .5s ease-in-out;
}

.pageButtons button:hover {
    background-color: #ebebeb;
    color: #0952b3;
}

.Dropdown-menu {
    position: absolute;
    background: white;
    border: 1px solid black;
    bottom: 100%;
    max-height: 30vh;
    width: 100%;
    overflow: auto;
    z-index: 1000;
}

.Dropdown-menu div {
    padding: 7px;
    cursor: pointer;
    transition: 0.3s;
    /* border-bottom: 1px solid black; */
}

.Dropdown-menu div:hover {
    background: lightblue;
}

.Dropdown-root {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 10px 0;
    /* padding: 10px; */
    border: 1px solid black;
    border-radius: 0.4rem;
    font-weight: normal;
    font-size: 0.8rem;
    cursor: pointer;
}

.Dropdown-root>div {
    padding: 10px;
}

/* div */
.gig-form-row {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    padding: 2em;
    padding-bottom: 0;
    min-width: 400px;
}

.gig-form-row.gig-type-selection input {
    width: 1em;
}


.gig-form-row:nth-last-child(1) {
    border-bottom: none;
}

.gig-form-row label {
    background: linear-gradient(271deg, #1B1B1B 54.57%, #0857C0 108.1%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.gig-type-selection label {
    width: fit-content;
    display: flex;
    gap: 1em;
    padding: 0 1em;

}

.gig-form-row input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    border-radius: 0.4rem;
}

.gig-form-row h1 {
    margin: 0.3em 0;
}

.gig-form-row p {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* background: linear-gradient(271deg, #1B1B1B 54.57%, #0857C0 108.1%); */
    /* background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    margin: 0.5em 0;
}

.Dropdown-root {
    width: 100%;
    margin: 0;
    margin-bottom: 0.5em;
}

.Dropdown-menu {
    margin: 0.4em 0;
    border-radius: 0.4rem;
}

.gig-form-row.categories {
    display: block;
}

.gig-description {
    display: block;
    box-sizing: border-box;
    resize: vertical;
    width: 100%;
    min-height: 10em;
    border-radius: 0.5em;
    margin: 0;
    padding: 0;
    font-size: 0.8em;
}

.thumbnail-image {
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 35%;
    border-radius: 0.75em;
}

.thumbnail-container {
    display: flex;
}

@media screen and (max-width: 1025px) {
    .gig-setup-page-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 426px) {
    .gig-setup-page-wrapper {
        flex-direction: column;
    }
}