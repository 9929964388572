.home-gig-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    height: 97%;
    margin: 5px 10px 10px 10px;
    text-align: center;
    justify-content: center;
    border-radius: 1em;
    background: #F9F9F9;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: 0.3s;
}

.home-gig-card:hover {
    transform: scale(1.03);
}

.home-gig-card img {
    width: 100%;
    margin-bottom: 10px;
    aspect-ratio: 16/11;
    object-fit: cover;
    border-radius: 1em 1em 0 0;
}

.home-gig-card h2 {
    font-size: 1em !important;
    flex: 1 !important;
    text-align: left !important;
    padding: 0 0.7em !important;
    font-weight: normal !important;
}

.home-gig-card p {
    margin: 0;
    color: #1B1B1B;
    font-size: 1em;
    font-style: normal;
    text-overflow: ellipsis;

    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: left;
}

.home-gig-card button {
    background: #0952B3;
    width: 100%;
    border-radius: 1em;
    margin: 2em 0 0 0;
    color: #FFF;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: 0.32px;
}

.home-gig-card button:hover {
    background-color: #EDB407;
}