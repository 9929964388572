.booking-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.booking-wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    margin: 1em var(--margin);
    gap: 2em;
}
.booking-wrapper-vertical {
    flex: 1;
    flex-direction: column;
    margin: 0;
    gap: 5em;
}

.booking-calendar-wrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
    background: var(--gray-background);
    padding: 2em 1em;
    border-radius: var(--std-radius);
    box-shadow: var(--std-shadow);
    z-index: 100;
}
.booking-consultant-details {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 2em auto;
}
.booking-consultant-profile-pic {
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}
.booking-consultant-details a {
    color: black;
}
.booking-consultant-details h3 {
    text-transform: capitalize;
    font-weight: 500;
}

.booking-wrapper-vertical .booking-calendar-wrapper {
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 80px;
    width: 100%;
    border-radius: 0 0 var(--std-radius) var(--std-radius);
    margin: 0;
    padding: 1em var(--margin);
}
.booking-wrapper-vertical .booking-consultant-details {
    margin: 0;
    flex-direction: row;
    align-items: center;
    gap: 2em;
}
.booking-wrapper-vertical .booking-consultant-profile-pic {
    width: 5em;
}

.booking-calendar {
    display: flex;
    width: fit-content;
    background: transparent !important;
    justify-content: center;
}

.calendar-session-indicator {
    background: var(--yellow) !important;
}


.booking-timeline {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    height: fit-content;
    /* justify-content: center; */
}
.booking-wrapper:not(.booking-wrapper-vertical) .booking-timeline {
    background: var(--gray-background);
    border-radius: var(--std-radius);
    box-shadow: var(--std-shadow);
    padding: 3em 2em;
}
.booking-timeline h2, .booking-timeline-vertical h2 {
    font-weight: 500;
    margin: 1em 0 2em 0;
}
.booking-wrapper-vertical .booking-timeline, .booking-wrapper-vertical .booking-timeline-vertical {
    margin: 0 var(--margin);
}
.booking-timeline-vertical {
    flex: 1;
}
.booking-timeline-vertical > div {
    display: flex;
    flex-direction: row;
    flex: 1;
    background: white;
    justify-content: center;
    gap: 1em;
}
/* .booking-timeline > div {
    position: relative;
} */
.booking-timeline-header, .booking-timeline-time-container {
    display: grid;
    grid-template-columns: repeat(24, minmax(0, 1fr))
}
.booking-timeline-header-vertical, .booking-timeline-time-container-vertical {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* grid-template-rows: repeat(24, minmax(0, 1fr)) */
}
.booking-timeline-container-vertical, .booking-timeline-time-container-vertical > div {
    flex: 1;
}

.booking-timeline-time-restraint {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: black;
    opacity: 0.4;
    cursor: not-allowed;
}

.booking-timeline-division {
    font-size: 0.8em;
    text-align: center;
}
.booking-timeline-division-vertical {
    font-size: 0.8em;
    text-align: right;
    padding: 1em 0;
}

.booking-timeline-timeline {
    height: 100px;
    border: 1px solid gray;
}
.booking-timeline-division:not(.booking-timeline-timeline):not(.booking-timeline-timeline-vertical) {
    transform-origin: 0 100%;
    transform: rotate(-45deg);
}

.booking-timeline-timeline-vertical {
    width: 100%;
    border: 1px solid gray;
}

.booking-timeline-container, .booking-timeline-container-vertical {
    position: relative;
    cursor: pointer;
}
.booking-timeline-session {
    position: absolute;
    background: var(--blue-default);
    height: 50%;
    top: 50%;
    transform: translateY(-50%);
    /* border: 1px solid black; */
    width: calc(100% / 24);
}
.booking-timeline-session-vertical {
    position: absolute;
    background: var(--blue-default);
    width: 100%;
    /* border: 1px solid black; */
    height: calc(100% / 24);
}

.booking-timeline-pointer {
    position: absolute;
    top: -2.5em;
    background: var(--blue-default);
    padding: 0.1em 0.3em;
    font-size: 0.8em;
    transform: translateX(-50%);
    color: whitesmoke;
    pointer-events: none;
}

.booking-timeline-pointer::after {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    background: var(--blue-default);
    height: 118px;
    left: 50%;
    transform: translateX(-50%);
}
/* vertical pointer */
.booking-timeline-pointer-vertical {
    position: absolute;
    left: -4em;
    background: var(--blue-default);
    padding: 0.1em 0.3em;
    font-size: 0.8em;
    transform: translateY(-50%);
    color: whitesmoke;
    pointer-events: none;
}

.booking-timeline-pointer-vertical::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background: var(--blue-default);
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}
.booking-timeline-marker {
    width: calc(100% / 24);
    background: var(--blue-default);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    opacity: 50%;
    z-index: 1;
    pointer-events: none;
    transition: background 0.3s;
}
.booking-timeline-marker-vertical {
    height: calc(100% / 24);
    background: var(--blue-default);
    position: absolute;
    width: 100%;
    opacity: 50%;
    z-index: 1;
    pointer-events: none;
    transition: background 0.3s;
}



/* BOOKING POPUP */
.booking-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    max-width: 850px;
    width: calc(100% - var(--margin));
    height: 80%;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    padding: 1em 0;
    overflow: auto;
    border-radius: var(--std-radius);
}

.booking-popup-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: auto;
}
.booking-popup-container {
    width: fit-content;
    max-width: 100%;
}

.booking-popup-cancel {
    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
    margin: 0 10px 10px auto;
}

.booking-popup-gig-thumbnail {
    width: calc(400px + 1em);
    max-width: calc(400px + 1em);
    aspect-ratio: 16/11;
    object-fit: cover;
}

.booking-gig-selector {
    height: calc(100% - 20px);
    padding: 10px;
}


.booking-popup-time-wrapper {
    margin: 1em var(--margin);
}
.booking-popup-time-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    /* margin: 1em var(--margin); */
}
.booking-popup-time-container > * {
    flex: 1;
    max-width: 200px;
    min-width: 150px !important;
}
.booking-popup-time-container .time-picker-container-session {
    margin: 0;
}
.booking-popup h3 {
    font-weight: 400;
    font-size: 1.2em;
    max-width: calc(400px + 1em);
}
.booking-popup-gig-select-label {
    margin-left: var(--margin);
}

.booking-popup-gig-information {
    margin: 1em var(--margin);
}
.booking-popup-book-button {
    background: var(--blue-default);
    color: whitesmoke;
    margin: var(--margin);
    height: fit-content;
    /* width: inherit; */
    flex: 1;
    border-radius: 0.5em;
    transition: 0.3s;
    padding: 1em;
}
.booking-popup-book-button:hover {
    transform: scale(1.03);
}


@media (max-width: 600px) {
    .booking-popup-gig-information .booking-popup-gig-thumbnail {
        width: 100%;        
    }
}
@media (max-width: 700px) {
    .booking-consultant-details h3 {
        display: none;
    }
    .booking-wrapper-vertical .booking-consultant-profile-pic {
        width: 4em;
    }
}