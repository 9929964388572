.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f1f1f1;
  flex-direction: column;
}

.register-form-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 80vh;
  width: 500px;
}

.register-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #202124;
}

.register-form-container form {
  display: flex;
  flex-direction: column;
}

.user-register-OTP-input {
  margin: 2em 0;
}

.register-OTP-resend-container {
  display: flex;
  gap: 1em;
}

.register-OTP-resend-container p {
  padding: 0;
  margin: 0 !important;
  font-size: 1em;
}

.register-OTP-resend-container button {
  padding: 0;
  margin: 0;
  font-size: 1em;
  color: var(--blue-default);
  cursor: pointer;
  border-bottom: 1px solid var(--blue-default);
}

.register-form-container input[type="text"],
.register-form-container input[type="email"],
.register-form-container input[type="password"],
.register-form-container input[type="tel"] {
  border: none;
  border-bottom: 1px solid #dadce0;
  padding: 10px 0;
  margin-bottom: 14px;
  font-size: 16px;
  color: #202124;
  outline: none;
}

.register-form-container input[type="text"]::placeholder,
.register-form-container input[type="email"]::placeholder,
.register-form-container input[type="password"]::placeholder,
.register-form-container input[type="tel"]::placeholder {
  color: #80868b;
}

.register-form-container .name-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

.registration-body {
  flex: 1;
}

.registration-nav-button-container {
  display: flex;
  justify-content: space-between;
}

.registration-nav-button-container button {
  background: var(--blue-default);
  color: whitesmoke;
  border-radius: 0.5em;
  transition: all 0.3s;
}

.registration-nav-button-container button:not(:disabled):hover {
  transform: scale(1.03);
}

.registration-nav-button-container button:disabled {
  background: gray;
  cursor: auto;
}

.register-progress-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1em;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.register-progress-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 1em;
  background: #04AA6D;
  cursor: pointer;
}

.register-progress-slider::-moz-range-thumb {
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}


.register-form-container .error {
  color: #f44336;
  margin-top: 4px;
  font-size: 14px;
}

.register-form-container .phone-number-field {
  position: relative;
}

.register-form-container .phone-number-field input[type="tel"] {
  padding-left: 10px;
}

.register-form-container .phone-number-field::before {
  content: "+";
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #80868b;
}

.register-form-container .register-button {
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-form-container .register-button:disabled {
  background: gray;
}

.register-form-container .register-button:not(:disabled):hover {
  background-color: #0c5fce;
}

.register-form-container p {
  /* text-align: center; */
  margin-top: 16px;
  font-size: 14px;
  color: #5f6368;
}

.register-form-container p a {
  color: #1a73e8;
  text-decoration: none;
}

.logo-container {
  text-align: center;
  margin-bottom: 24px;
}

.registry-email-field {
  position: relative;
  width: 100%;
}
.registry-email-field input {
  width: 100%;
}

.logo {
  width: 100px;
  height: auto;
}

.show-password-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #202124;
  font-size: 14px;
}

.show-password-checkbox input[type="checkbox"] {
  margin-left: 8px;
}

.loading-icon {
  animation: spin 1s infinite linear;
}


/* PASSWORD */
.password-field-strength {
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  bottom: 10px;
  /* background-color: #8A9A5B; */
}



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 426px) {
  .register-form-container {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .register-form-container .name-fields {
    display: block;
  }

  .register-form-container input[type="text"],
  .register-form-container input[type="password"] {
    width: 100%;
  }
}