.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f1f1f1;
  flex-direction: column;
}

.logo-container {
  text-align: center;
  margin-bottom: 24px;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  width: 100% !important;
}

.logo {
  width: 100px;
  height: auto;
}

.login-form-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.login-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #202124;
}

.login-form-container form {
  display: flex;
  flex-direction: column;
}

.login-form-container input[type="email"],
.login-form-container input[type="password"],
.login-form-container input[type="text"] {
  border: none;
  border-bottom: 1px solid #dadce0;
  padding: 10px 0;
  margin-bottom: 16px;
  font-size: 16px;
  color: #202124;
  outline: none;
}

.login-form-container input[type="email"]::placeholder,
.login-form-container input[type="password"]::placeholder {
  color: #80868b;
}

.login-form-container button[type="submit"] {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-form-container button[type="submit"]:hover {
  background-color: #0c5fce;
}

.separator {
  text-align: center;
  margin: 16px 0;
}

.separator span {
  display: inline-block;
  background-color: #fff;
  padding: 0 8px;
  font-size: 14px;
  color: #5f6368;
}

.google-login {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 16px;
  font-size: 16px;
  color: #202124;
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-login:hover {
  background-color: #f8f9fa;
}

.google-login .google-logo {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.login-nav {
  text-align: center;
  font-size: 14px;
  color: #5f6368;
}

.login-nav a {
  color: #1a73e8;
  text-decoration: none;
  transition: 0.2s;
}

.login-nav a:hover {
  color: #1a73e8;
  box-shadow: #1a73e8 0 1px 0px;
}

.error {
  color: red;
  font-size: 14px;
}

.show-password-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #202124;
  font-size: 14px;
}

.show-password-checkbox input[type="checkbox"] {
  margin-left: 8px;
}

.back-button-wrapper {
  text-align: center;
}

.back-button {
  background-color: white;
  color: rgb(51, 51, 51);
  border: none;
  padding: 10px 0px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;

  margin: 15px auto;
}

.back-button:hover {
  background-color: rgb(148, 148, 148);
}

.back-button:active {
  background-color: rgb(148, 148, 148);
}

.loading-icon {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.password-field {
  display: flex;
  flex-direction: row;
  width: fit-content;
  position: relative;
}

.password-field.login {
  width: 100%;
}

.password-field svg {
  position: absolute;
  right: 0;
  margin-top: 12px;
  color: gray;
}

::-ms-reveal {
  display: none;
}

.forgot-password-button {
  text-align: right;
  margin: 7px 0;
}

.forgot-password-button button {
  color: #5f5f5f;
  transition: 0.3s;
  font-size: 0.8em;
}

.forgot-password-button button:hover {
  box-shadow: #9f9f9f 0px 1px 0px 0px;
}

@media screen and (max-width: 321px) {
  .login-form-container {
    width: 100%;
  }
}