/* Parent container */
.chat-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 2em;
    padding: 2% 7% 0;
    height: calc(100vh - 70px);
    background-color: #f3f2ef;
    font-family: Arial, sans-serif;
}

/* Chat list container */
.chat-list {
    /* width: 40%; */
    /* width: 45em; */
    background-color: #fff;
    /* margin: 20px 10px 20px 70px; */
    overflow-y: auto;
    border-radius: 20px;
    height: 95%;
    overflow: visible;
    box-shadow: var(--std-shadow);
}


/* Chat item */
.chat-item-container{
    padding: 10px;
}

.chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: var(--std-delta-time);
    border-radius: 20px;
}

.chat-item:hover {
    /* background-color: #ebebeb; */
    transform: scale(1.01);
}

.chat-item.active {
    background-color: #ebebeb;
}

.chat-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
}

.chat-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat-details {
    flex: 1;
}

.chat-sender {
    font-weight: 550;
}

.chat-outer-box {
    position: relative;
    width: 100%;
    height: 95%;
    /* margin: 20px 70px 20px 10px; */
    position: relative;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: var(--std-shadow);
    display: flex;
    flex-direction: column;
}

/* Chat box */
.chat-box {
    flex: 1;
    padding: 20px;
    padding-top: 80px;
    overflow-y: auto;
}

.empty-chat-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Chat-heading {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0 2em;
    gap: 0.2em;
    height: 72px;
    position: absolute;
    border-bottom: 1px solid #e1e1e1;
    background-color: #f9f9f9d7;
    backdrop-filter: blur(7px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.chat-heading-name {
    display: flex;
    align-items: center;
}
.Chat-heading h2 {
    font-size: 1.1em;
    padding: 20px;
}
.Chat-heading img {
    height: 2em;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
}
.Chat-heading button {
    border-radius: 0.7em;
    transition: var(--std-delta-time);
    box-shadow: var(--std-shadow);
}
.Chat-heading button:hover {
    background: rgba(190, 14, 14, 0.746);
    color: white;
    transform: scale(1.03);
}

.load-more-container {
    text-align: center;
    align-self: center;
}
.load-more-button {
    margin: auto;
    background: #f9f9f9;
    padding: 10px 20px;
    color: #1b1b1b;
    border-radius: 1em;
    box-shadow: 0 2px 5px #1b1b1b31;
}

.Send-btn {
    background-color: #0a66c2;
    color: #ffffff;
    font-size: 0.65em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: none;
    margin: 0 5px;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    transition: var(--std-delta-time);
}

.Send-btn:hover {
    /* background-color: #094c86; */
    background: #1076d2;
    transform: scale(1.03);
}

.Send-btn .send-icon{
    display: none;
}

.chat-message {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}

.receiver-chat-message {
    display: flex;
    justify-content: flex-start;
}

.receiver-message-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1e1e1;
    color: #333333;
    padding: 10px 20px;
    border-radius: 50px;
    border-bottom-left-radius: 0px;
}

.message-content, .attachment--chat-message .receiver-message-content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    background-color: #0a66c2;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 50px;
    margin-left: auto;
    border-bottom-right-radius: 0px;
}

.join-link-button {
    text-decoration: none;
    background: #0a66c2;
    width: 90%;
    padding: 0.3em 2em;
    color: white;
    border-radius: 1em;
    box-shadow: var(--std-shadow);
    transition: var(--std-delta-time);
}
.join-link-button:hover {
    background: #1076d2;
    transform: scale(1.03);
}


.message-header {
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
    background-color: #f9f9f9d7;
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 72px;
    gap: 2em;
}

.messaging-heading {
    font-size: 1.2em;
    padding: 10px 10px;
    font-weight: bold;
    color: #333333;

}

.messaging-nav-bar {
    position: relative;
    display: flex;
    width: fit-content;
    background-color: #f4f4f4;
    box-shadow: none;
    border-radius: 20px;
}

.messaging-nav-bar button:nth-child(1){
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.messaging-nav-bar button:nth-child(2){
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.nav-item {
    box-shadow: 0 3px 5px #1b1b1b23;
    background-color: #f4f4f4;
    padding: 10px 15px;
    font-size: 0.85em;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
    text-transform: capitalize;
    flex: 1;
    min-width: 7.2em;
    transition: var(--std-delta-time);
    transform-origin: center right;
}
.nav-item:nth-child(2) {
    transform-origin: center left;
}
.nav-item:hover {
    transform: scale(1.02);
}

.nav-item.active{
    background-color: #0952b3;
    color: #fff;
}

.chat-input {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    padding: 0 10px;
    background-color: #f9f9f9;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.chat-input input {
    width: 100%;
    padding: 8px 1em;
    border: none;
    border-radius: 30px;
    outline: none;
}

.chat-input .input-container {
    flex: 1;
    border-radius: 20px;
    margin: 0 5px;
}

.icon-container {
    display: flex;
    background-color: transparent;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 20px;
    
}

.icon-container .icon-button{
    margin: 0;
    padding: 0;
    background-color: inherit;
    height: 18px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;

}

.gig-header {
    display: flex;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    background: #fafafa;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #e1e1e1;
    height: fit-content;
}
.gig-header h3 {
    margin: 0 !important;
}

.gig-dialog-wrapper {
    /* display: none; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 70vh;
    z-index: 9999;
    overflow: auto;
    align-items: center;
}


.gig-dialog-content {
    background-color: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    border-radius: 1.3em;
}
.gig-dialog-gigs {
    flex: 1;
    overflow: auto;
}

.gig-card {
    margin: 10px 0;
    padding: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
    display: flex;
    cursor: pointer;
    transition: 0.2s;
    border-bottom: 1px solid rgba(193, 193, 193, 0.428);
}
.gig-card:hover {
    background: rgba(0, 0, 0, 0.117);
}

.gig-card img {
    width: 100px;
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 0.2em;
}

.gig-card span {
    width: 100%;
    text-align: justify;
}

.gig-dialog-content h3 {
    text-align: center;
    margin-bottom: 2vh;
}

.gig-dialog-close {
    background-color: rgb(239, 238, 238);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s;
}

.gig-dialog-close:hover {
    background-color: red;
}

.gig-card .gig-desc {
    flex-grow: 1;
    /* Add this line to make the description take up remaining space */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    margin: 10px;
    box-sizing: border-box;
    margin-left: 20px;
    /* Add this line to create the 20px gap */
}

.gig-card .gig-title {
    font-size: 16px;
    font-weight: bold;
    text-align: start;
    margin: 0;
    /* Add this line to remove default margin */
}

.price-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.details-btn {
    background-color: #0a66c2;
    color: #f3f2ef;
    border-radius: 10px;

}

.details-btn:hover {
    background-color: #094c86;
}

.gig-card .gig-price {
    font-size: 14px;
    color: #999;
    margin: 0;
    /* Add this line to remove default margin */
}

.hidden-div {
    display: none;
}

/* Second gig */
.second-gig-detail {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    text-align: center;
    position: relative;
    overflow: auto;
}

.second-gig-card {
    background: #fafafa;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 40px;
    height: 100px;
    display: flex;
}

.second-gig-card img {
    height: 5.5em;
    object-fit: cover;
    aspect-ratio: 16/9;
}

.second-gig-card .second-gig-desc {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* gap: 30px; */
    margin-left: 20px;
}

.second-gig-card .second-gig-title {
    font-size: 16px;
    font-weight: bold;
    text-align: start;
    margin: 0;
}

.second-price-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.send-gig-msg {
    background-color: #0a66c2;
    color: white;
    border-radius: 10px;
    margin-left: 10px;
}

.send-gig-msg:hover {
    background-color: #094c86;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    width: 100%;
    flex: 1;
    scroll-snap-type: y proximity;
    overflow: auto;
}
.inputs > div{
    width: 100%;
    flex: 1;
    scroll-snap-align: start;
}

.inputs > div:not(.second-gig-card) {
    flex: 1;
    /* min-height: 90%; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid gray;
}

.inputs > div:not(.second-gig-card) h3 {
    padding: 1em;
}

.datetime-input {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.gig-msg-input {
    padding: 10px;
    width: 80%;
    align-self: center;
}

.datetime-input {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.gig-msg-input {
    padding: 10px;
}

.chat-message-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
}

/* .attachment--chat-message {
    gap: 1em;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-end;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px 10px 30px 10px;
    margin-left: auto;
} */

/* .attachment--chat-message .receiver-message-content{
    border-radius: 10px;
    width: 80%;
    text-align: justify;
    border-radius: 100px;
    border-bottom-right-radius: 0;
} */

.attachment-receiver-chat-message, .attachment--chat-message {
    display: flex;
    width: 60%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    flex-direction: column;
}

.attachment--chat-message {
    display: flex;
    width: 60%;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    flex-direction: column;

    margin-left: auto;
}

/* .attachment-card {
    gap: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px 10px;
    margin-top: 8px;
    margin-bottom: 5px;
}

.attachment-card img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
} */

.attachment-details {
    width: 90%;
    text-align: left;
}

.attachment-title {
    font-weight: bold;
    margin-bottom: 4px;
}

.attachment-size {
    font-size: 12px;
    color: #888888;
}

.receiver-attachment-card, .attachment-card {
    gap: 1em;
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 20px 10px;
    margin: 10px 0;
}

.receiver-attachment-card img, .attachment-card img {
    object-fit: cover;
    aspect-ratio: 16/9;
    width: 100%;
    border-radius: 20px;
}

.receiver-attachment-details {
    gap: 1px;
}

.receiver-attachment-title {
    font-weight: bold;
    font-size: 1.2em;
}

.receiver-attachment-size {
    font-size: 12px;
    color: #888888;
}

.attachment-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 80%;
}
.attachment-buttons button {
    font-size: 0.7em;
    background-color: #0a66c2;
    text-align: center;
    color: white;
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    width: fit-content;
    border-radius: 10px;
    box-shadow: var(--std-shadow);
    transition: var(--std-delta-time);
}
.attachment-buttons button:hover {
    background: #1076d2;
    transform: scale(1.03);
}

.session-description {
    display: flex;
    text-align: left;
    width: 90%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    font-size: 0.8em;
}

.date-time {
    display: flex;
    flex-direction: column;
}

/* 
.respond-button {
    background-color: #094c86;
    text-align: center;
    color: white;
    cursor: pointer;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 10px;
} */
/* 
.accept-button {

} */

/* Additional styling for the header */
h1 {
    margin-bottom: 20px;
}

.chat-last-message {
    font-size: 0.7em;
    font-weight: normal;
}

.last-message-not-seen {
    font-weight: bold;
}
.last-message-not-seen::after {
    content: '';
    display: block;
    background: black;
    width: 0.5em;
    aspect-ratio: 1/1;
    border-radius: 50%;
    float: right;
}



.background-overlay {
    position: fixed !important;
    width: 100% !important;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    border-radius: 0 !important;
    overflow: hidden !important;
    background: rgba(0, 0, 0, 0.452) !important;
}

@media(max-width: 768px){

    .messaging-heading{
        font-size: 1em;
    }

    .Send-btn .send-icon{
        display: block;
        filter: brightness(100);
        width: 15px;
    }

    .icon-container{
        gap: 6px;
    }

    .icon-container button i{
        width: 5px;
    }

    .icon-container button i svg{
        width: 12px;
    }

    .Send-btn{
        padding: 10px;
    }

    .Send-btn p{
        display: none;
    }



}

@media(max-width: 700px){

    .chat-container {
        padding: 0;
        padding-top: 2%;
    }

    .chat-list{
        width: 100%;
        border-radius: 0;
    }
    
    .chat-list.inactive{
        display: none;
    }
    
    .chat-outer-box{
        width: 100%;
        border-radius: 0;
    }
    
    .chat-outer-box.inactive{
        display: none;
    }
}


/* SCROLL-BAR */
/* width */
.blue-scrollbar::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.blue-scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    border-radius: 1em;
}

/* Handle */
.blue-scrollbar::-webkit-scrollbar-thumb {
    background: var(--blue-default);
    border-radius: 1em;
}








.chat-box::-webkit-scrollbar-track {
    margin: 82px 0 10px 0;
}