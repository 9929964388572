.password-field-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.password-field-strength {
    transition: 0.3s;
}

.correct-password-yes, .correct-password-no {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 5px;
    background: #8a9a5b;
    transition: 0.5s;
}
.correct-password-no {
    background: #ff825B;
}

.password-field-wrapper {
    display: flex;
    align-items: center;
    gap: 1em;
}
.password-input-field {
    position: relative;
}