h1,
h2,
p {
  margin: 0;
}

.gig-inquire-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.gig-main {
  display: flex;
  justify-content: center;
  min-height: 100%;
  max-width: 1300px;
  margin: auto;
}

.gig-megacontainer {
  display: flex;
  gap: 5em;
  width: 100%;
  margin: 0 var(--margin);
  max-width: 1300px;
  justify-content: space-between;
}

.gig-container {
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  width: 60%;
}

.gig-informations {
  display: block;
  padding: 0px;
  width: 100%;
}

.gig-informations .gig-title {
  font-size: 1.65em;
  margin: 50px 0 10px 0;
}

.gig-description-container h2,
.gig-description-container h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gig-like-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.4em;
  transition: 0.3s ease-in-out;
}

.gig-like-button:hover {
  transform: scale(1.1);
}

.gig-like-button.liked {
  color: blue;
}

.gig-like-button.not-liked {
  color: gray;
}

.gig-time-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gig-time {
  font-size: .8em;
}

.gig-thumbnail {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
  border: none;
  margin: 12px 0;
}

.gig-description-container {
  margin-top: 0px;
}

.gig-reviews {
  margin: 20px 0px;
  font-size: 1em;
  max-width: 20em;
}

.gig-reviews h2 {
  margin-top: 30px;
}

.MuiRating-decimal.css-34he1w-MuiRating-decimal svg {
  width: 18px;
  height: auto;
}

.gig-rating {
  color: yellow;
  font-size: 0.7em;
  text-shadow: 2px 2px red;
}

.gig-about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gig-descriptions {
  font-size: 1em;
  margin: 10px 0 20px;
  padding-right: 5px;
  text-align: justify;
  background: var(--gray-background);
  /* box-shadow: 0px 3px 10px 3px rgb(219, 219, 219); */
  box-shadow: var(--std-shadow);
  padding: 1em;
  border-radius: 1em;
}
.gig-prerequisite {
  margin: 0.3em 0 !important;
}

.gig-descriptions1 * {
  all: revert;
}

.div-filler {
  width: 30%;
}

.gig-pfp-edit-button {
  background: #0952B3;
  z-index: 100;
  width: fit-content;
  /* aspect-ratio: 1/1; */
  color: #fff;
  border-radius: 10px;
  padding: 10px 30px;
  transition: 0.3s;
}

.gig-pfp-edit-button:hover {
  background: #5c7fad;
}

.gig-pfp-edit-button::before {
  content: 'Edit ';
}

.additional-informations-wrapper {
  position: sticky;
  top: 78px;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 3px 10px 3px #1b1b1b13;
  max-height: 80vh;
  border-radius: 0 0 20px 20px;
}

.additional-informations {
  display: flex;
  max-width: 1300px;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em var(--margin);
  margin: auto;
}

.additional-informations .gig-consultant-info-div {
  display: flex;
  align-items: center;
  border-right: 1px solid gray;
  padding-right: 3em;
  max-width: 200px
  /* width: 350px; */
}

.additional-informations .gig-consultant-datetime-div {
  display: flex;
  align-items: center;
}

.gig-pfp-consultant-linker {
  width: 90%;
  height: 90%;
  aspect-ratio: 1/1;
}
.additional-informations img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  border-radius: 100%;
  box-shadow: 0px 0px 0 3px #fff;
}

.additional-informations .gig-pfp-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 3px solid #0952B3;
  margin-right: 10px;
}

.additional-informations .gig-consultant-name {
  display: block;
  font-size: 1.1em;
  /* margin-top: 10px; */
}

.additional-informations .gig-consultant-enterprise {
  display: block;
  font-size: 0.9em;
  color: gray;
}

.additional-informations p {
  font-size: .9em;
  margin: 5px 0;
}

.additional-informations .gig-price {
  font-size: 1.2em;
  margin: 5px 0;
}

.additional-informations .gig-message-button {
  color: #fff;
  background-color: #0952B3;
  width: 150px;
  height: 100%;
  padding: 1em 0;
  border-radius: 5px;
  transition: 0.3s;
  text-align: center;
}
.gig-message-button.gig-check-my-session-button {
  width: fit-content !important;
  padding: 0.9em 0.5em !important;
  max-width: 170px;
}

.additional-informations .gig-message-button:hover {
  background-color: #cad3df;
}

.additional-informations .gig-instant-booking-button {
  border: 1px solid #bdbdbd;
  color: gray;
  font-size: 1em;
  border-radius: 5px;
  height: 100%;
  padding: 16px 0;
  width: 150px;

  margin-right: 10px;
}

.gig-message-text {
  color: #fff;
  text-decoration: none;
}

.gig-reviews .review-block {
  border: none;
  border-bottom: 0.8px solid #5757575d;
  padding: 30px 0;

}

.gig-reviews .review-block>img {
  width: 3em;
  height: 3em;
  border-radius: 100%;
  margin-right: 10px;
  object-fit: cover;
}

.gig-reviews .gig-review-text {
  font-size: 0.9em;
}

.gig-review-rating svg {
  width: 14px;
  height: auto;
}

.gig-message- {
  background: lightblue;
  border-radius: 10px;
}

.gig-inquire-button {
  float: right;
  background: lightblue;
  border-radius: 10px;
  margin: 10px;
}


.gig-consultant-pfp {
  width: 25%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}

.gig-booking-button-wrapper {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  flex: none;
}

.gigpage-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  /* height: 150px; */
  padding: 1.5em 1em;
  /* text-align: center; */
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  z-index: 1001;
}

.gigpage-popup-section {
  display: flex;
  padding: 0;
  margin: 2em 0 0 0;
  justify-content: space-between;
}

.gigpage-popup button {
  border-radius: 10px;
  color: white;
  transition: 0.3s;
}

.gig-booking-close-icon:hover {
  transform: scale(1.03);
}

.gig-confirmation-button {
  background-color: #0952B3;
  margin: 2em 0 0 0;
}

.gig-cancel-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  /* width: fit-content; */
  border-radius: 50% !important;
  padding: 0;
  width: 1.2em;
  aspect-ratio: 1/1;
  color: white;
  background-color: #c1121f;
}

.gig-profile-name {
  text-transform: capitalize;
}

.gig-booking-close-icon {
  margin-left: 20px;
  color: #c1121f;
  font-size: 1.5em;
  transition: 0.3s;
  cursor: pointer;
}

.gigpage-popup button:hover {
  transform: scale(1.03);
}

.empty-reviews-illustration {
  text-align: center;
}
.empty-reviews-illustration img {
  width: 100%;
}


@media(max-width: 1350px) {
  .gig-title {
    font-size: 2em;
  }
}

@media(max-width: 1280px) {
  .MuiRating-decimal.css-34he1w-MuiRating-decimal svg {
    width: 13px;
    height: auto;
  }

  .gig-informations .gig-time {
    font-size: 0.72em;
  }
}

@media(max-width: 1100px) {
  .additional-informations {
    justify-content: space-between;
    padding: 0.3em var(--margin);
  }

  .additional-informations .gig-consultant-info-div {
    /* width: 250px; */
    padding-right: 0.4em;
  }

  .additional-informations .gig-consultant-name {
    font-size: 0.9em;
  }

  .additional-informations .gig-message-button {
    color: #fff;
    width: 80px;
    height: 100%;
    border-radius: 5px;
    transition: 0.3s;
  }

  /* .additional-informations .gig-pfp {
    width: 55px;
    height: 55px;
  } */

  .additional-informations .gig-pfp-border {
    width: 70px;
  }

  .gig-pfp-edit-button {
    right: calc(15% + 275px/2);
  }

  .MuiRating-decimal.css-34he1w-MuiRating-decimal svg {
    width: 15px;
    height: auto;
  }

  .gig-informations .gig-time {
    font-size: 0.9em;
  }
}

@media(max-width: 950px) {
  .gig-main {
    padding: 0;
    margin: 0;
  }

  .gig-megacontainer {
    width: 90%;
    font-size: 0.9em;
  }

  .gig-informations {
    padding: 0;
  }

  .gig-reviews {
    margin: 20px 0px;
  }

  .additional-informations {
    position: static;
    width: 100%;
    /* height: 350px; */
    transform: translate(0);
    justify-content: space-between;
    /* background-color: #1b1b1b19; */
    /* box-shadow: none; */
    /* margin: 50px 0; */
    /* padding: 50px 0; */
  }

  .gig-pfp-edit-button {
    right: 30px;
    top: 100px;
    transform: none;
    aspect-ratio: 1/1;
    border-radius: 50%;
    padding: 15px;
  }

  .gig-pfp-edit-button::before {
    content: '';
  }

  /* .additional-informations img {
    width: 100px;
    height: 100px;
    aspect-ratio: 1;
    margin: 0;
  } */

  .additional-informations .gig-pfp-border {
    width: 60px;
    /* height: 70px; */

  }

  .additional-informations .gig-consultant-name {
    font-size: 1em;
    margin: 0;
  }

  /* .additional-informations .gig-consultant-info-div {
    width: 210px;
  } */

  .additional-informations .gig-price {
    font-size: 1em;
  }

  .div-filler {
    display: none;
  }

  .MuiRating-decimal.css-34he1w-MuiRating-decimal svg {
    width: .6em;
    height: auto;
  }

  .additional-informations .gig-instant-booking-button {
    width: 115px;
  }

  .additional-informations .gig-message-button {
    padding: 1em 0;
    width: 60px;
  }

  .css-15xsz9q-MuiFormControl-root-MuiTextField-root {
    width: 130px;
  }

}

@media(max-width: 800px) {
  .gig-megacontainer {
    width: 100%;
    flex-direction: column;
    font-size: 0.8em;
  }

  .gig-container {
    width: 100%;
  }

  /* .additional-informations .gig-consultant-info-div {
    width: 130px;
  } */

  /* .additional-informations img {
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    margin: 0;
    padding: 0;
    display: none;
  } */

  .additional-informations .gig-consultant-info-div > div:not(.gig-pfp-border) {
    width: 20px;
    height: 20px;
    display: none;
  }

  .additional-informations .gig-instant-booking-button {
    width: 110px;
    /* padding: 0.6em 0.2em; */
  }

  .additional-informations {
    border-radius: 10px;
    margin: 0;
    flex-wrap: wrap;
    /* padding: 0.8em 1em; */
    /* navbar padding 24px */
    /* padding-left: 24px;
    padding-right: 24px; */
  }
}

.sign-in-message-unauthorized {
  background: rgba(56, 56, 56, 0.26);
  padding: 0.2em 1em;
  color: rgb(230, 25, 25);
  border-radius: 0.5em;
  font-weight: 600;
  font-size: 0.6em !important;
  margin-top: 1.5em;
  margin-bottom: -0.6em !important;
}

@media (max-width: 770px) {
  /* .additional-informations {
    padding: 1em var(--margin);
  } */

  .additional-informations .gig-message-button,
  .additional-informations .gig-instant-booking-button {
    width: 120px;
  }

  .gig-booking-close-icon {
    margin-left: 7em;
    /* color: darkred; */
    /* font-size: 2em; */
  }
}

@media (max-width: 460px) {
  .additional-informations .gig-message-button {
    width: 80px;
    font-size: 1em;
  }

  .additional-informations .gig-instant-booking-button {
    font-size: 1em;
    width: 60px;
  }

  .additional-informations .gig-consultant-name {
    font-size: 0.9em;
  }

  .additional-informations .gig-consultant-enterprise {
    font-size: 0.8em;
  }

  /* .additional-informations .gig-consultant-info-div {
    width: 100px;
  } */

  .additional-informations .gig-consultant-datetime-div {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .css-15xsz9q-MuiFormControl-root-MuiTextField-root {
    width: 175px;
    /* margin-right: 20px !important; */
  }

  .gig-booking-close-icon {
    margin-left: 5em;
    /* font-size: 2em; */
  }
}

.gig-descriptions > div {
  margin: 1em 0;
}
.gig-descriptions > div > h3 {
  font-size: 1em;
  font-weight: 600;
  margin: 0;
}
.gig-descriptions > div > a > h3 {
  font-size: 1.2em;
  margin: 0;
}
.gig-descriptions > div > a {
  display: block;
  background: var(--blue-default);
  width: fit-content;
  color: whitesmoke;
  padding: 1em 1em;
  border-radius: 0.5em;
  font-size: 0.8em;
  margin: 0.3em 0;
  transition: 0.3s;
}
.gig-descriptions > div > a:hover {
  transform: scale(1.03);
}

.gig-descriptions .days-selector {
  border: none;
  padding: 0;
  margin-top: 0.2em;
}
.gig-descriptions .days-selector button {
  flex: 1;
  cursor: not-allowed;
}
.gig-page-avaibalility .gig-setup-day-select {
  background: gray !important;
}

.gig-page-available-time-container {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.time-picker-container-gig {
  padding: 0.2em 1em;
  background: var(--blue-default);
  color: whitesmoke;
  border-radius: 0.5em;
}