.settings-popup {
    position: absolute;
    z-index: 1001;
    width: 80%;
    height: 87%;
    max-width: 900px;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    padding: 2em 0 0 0;
    background: var(--gray-background);
    border-radius: 1em;
}

.gig-cancel-button {
    position: block !important;
    margin-left: auto;
}

.settings-main-wrapper {
    height: 100%;
}

.settings-main-container {
    height: 100%;
    padding: 0 3em;
    overflow: auto;
}

.settings-main-container > div {
    padding: 2em 0;
}

.settings-general-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between;
}
.settings-general-details {
    display: inline-grid;
}
.settings-general-details > div {
    display: flex;
    align-items: center;
    gap: 1em;
}
.settings-general-details h3 {
    font-weight: 200;
    font-size: 0.9em;
    width: 7em;
    text-align: right;
}

.settings-main-wrapper h2 {
    font-weight: 300;
    margin-bottom: 1em;
}
.settings-general-details input {
    border: none;
    border-bottom: 1px solid #dadce0;
    background: white;
    color: gray;
    padding: 7px 5px;
    font-size: 1em;
    outline: none;
    cursor: not-allowed;
    max-width: 280px;
    width: 280px;
}

.settings-privacy-container input {
    border: none;
    border-bottom: 1px solid #dadce0;
    padding: 10px;
    margin-bottom: 16px;
    font-size: 1em;
    color: #202124;
    outline: none;
}
.settings-profile-picture {
    width: 14em;
}
.settings-profile-pic {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 0.5em;
}

#profile-input {
    display: none;
}
.settings-change-profile-button, .settings-profile-picture > button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background: var(--blue-default);
    color: whitesmoke;
    border-radius: 1em;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: 0.3s;
}
.settings-change-profile-button:hover, .settings-profile-picture > button:hover {
    transform: scale(1.03);
}
.settings-change-profile-button::after {
    content: " Change Profile Picture";
}
.settings-save-profile-button::after {
    content: " Save Profile Picture";
}

.settings-privacy-current-password {
    display: flex;
    align-items: center;
    gap: 1em;
}
.settings-privacy-container h3 {
    font-weight: 200;
    font-size: 0.9em;
    width: 9em;
}

.settings-change-password-button {
    background: var(--blue-default);
    color: whitesmoke;
    border-radius: 1em;
    margin: 1em 0;
    transition: 0.3s;
}

.settings-change-password-button:hover {
    transform: scale(1.03);
}



@media (max-width: 920px) {
    .settings-main-container {
        font-size: 0.9em;
    }
    .settings-general-details input {
        max-width: 180px;
    }
}
@media (max-width: 740px) {
    .settings-popup {
        width: 90%;
    }
}
@media (max-width: 653px) {
    .settings-general-container {
        gap: 4em;
    }
    .settings-general-details h3 {
        text-align: left;
        width: 9em;
    }
}

@media (max-width: 450px) {
    .settings-popup {
        top: 7%;
        height: 90%;
    }
    .settings-main-container {
        padding: 0 2em;
    }
    .settings-general-details {
        width: 100%;
    }
    .settings-profile-picture {
        margin: auto;
    }
    .settings-general-details > div {
        flex-direction: column;
        gap: 0.5em;
        align-items: baseline;
        margin: 0.4em 0;
    }
    .settings-general-details input {
        max-width: 100%;
        width: 100%;
    }
    .settings-general-details h3 {
        width: auto;
        font-size: 1.1em;
    }
    .settings-privacy-current-password, .password-field-wrapper {
        flex-direction: column;
        align-items: baseline;
        gap: 0.5em;
    }
    .settings-privacy-current-password h3, .password-field-wrapper h3 {
        font-size: 1.1em;
    }
    .settings-privacy-current-password input, .password-field-wrapper input {
        width: 100%;
    }
    .password-field, .password-field * {
        width: 100%;
    }

    .settings-profile-picture {
        width: 100px;
    }
    .settings-profile-pic {
        border-radius: 50%;
        border: 3px solid #0952b3;
    }
    .settings-change-profile-button::after {
        content: " Change";
    }
    .settings-save-profile-button::after {
        content: " Save";
    }
}