.request-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 80px);
    background: #f1f1f1;
}

.request-container-inner {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 4em 3em;
}

.request-response-container {
    display: flex;
    justify-content: space-between;
    gap: 2em;
    flex-wrap: wrap;
}
.request-container button {
    background: #0952b3;
    color: whitesmoke;
    border-radius: 0.8em;
    transition: 0.3s;
}
.request-container button:hover {
    transform: scale(1.03);
}