/* CSS for prof.css */

.prof-container1 .rating {
    text-align: center;
    font-size: 0.9em;
    margin: 1em;
  }
  
  .prof-container1 .profile-image {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 195px;
    height: 195px;
    border: 5px solid #0857C0;
    border-radius: 100%;
    margin-top: 4em;
  }
  
  .prof-container1 .profile-image img {
    aspect-ratio: 1/1;
    height: 189px;
    width: 189px;
    border: 5px solid #ffffff;
    border-radius: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  
  .eye-icon {
    width: 0.9em;
  }
  
  .joined-active-recommended {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* height: 100px; */
    text-align: center;
  }
  
  .joined-active-recommended .link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
  }
  
  .gigs-content>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .gigs-content>div>h2 {
    align-self: center;
    margin: 20px;
  }
  
  .gigs-content .column {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .gigs-content .GigThumbnail {
    width: 100%;
    height: auto;
    aspect-ratio: 16/11;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 8px #1b1b1b1a;
  }
  
  .gigs-content .GigThumbnail h2 {
    font-size: 1.6em;
    font-weight: 500;
    margin: 0 0 10px 0;
  }
  
  .gigs-content .GigThumbnail .gig-thumbnail-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .gigs-content .GigThumbnail .gig-thumbnail-right .edit-link {
    display: flex;
    justify-content: center;
  }
  
  .gigs-content .GigThumbnail .gig-thumbnail-right .edit-link button {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 0;
    background-color: #0952b3;
  
  }
  
  .gigs-content .GigThumbnail .gig-thumbnail-left>span>span {
    height: 0;
  }
  
  .gigs-content .GigThumbnail .gig-thumbnail-left>span {
    padding: 0;
  }
  
  .gigs-content .GigThumbnail img {
    padding: 10px;
    border-radius: inherit;
    height: auto;
  }
  
  .GigThumbnail .MuiRating-root * {
    padding: 0 !important;
  }
  
  .profile-image {
    aspect-ratio: 1/1;
    height: 192px;
    border: 5px solid #0857C0;
    border-radius: 50%;
  }
  
  .profile-image img {
    height: 189px;
    aspect-ratio: 1/1;
    border: 2px solid #ffffff;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  
  .upload-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }

  .upload-popup-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    /* flex-wrap: wrap; */
  }
  .upload-popup img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 0.5em;
  }
  .upload-popup input {
    display: none;
  }
  .profile-upload-label {
    display: block;
    position: relative;
    max-height: 300px;
    aspect-ratio: 1/1;
    cursor: pointer;
    transition: 0.3s;
  }
  .profile-upload-label:hover {
    /* opacity: 0.5; */
    /* filter: grayscale(20%); */
    filter: contrast(80%);
  }
  
  .upload-popup-label {
    margin: 20px 0 30px;
    font-weight: 10;
    font-size: 1.5em;
  }

  .profile-input-button-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 1em;
  }
  
  .upload-popup input[type="file"] {
    margin-bottom: 10px;
  }
  
  .upload-popup button:not(.gig-cancel-button), .upload-profile-pic-button {
    padding: 0.5em 1em;
    background-color: #0952b3;
    color: #fff;
    border: none;
    border-radius: 0.7em;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .upload-popup button:not(:disabled):hover, .upload-profile-pic-button:hover {
    /* background-color: #007bff; */
    transform: scale(1.03);
  }
  .upload-popup button:disabled {
    background: gray;
  }

  @media(max-width: 450px) {
    .upload-popup-container {
      flex-direction: column;
    }
  }
  
  .username {
    margin-top: 20px;
    text-align: center;
  }
  
  .username p {
    font-weight: 500;
    font-size: 22px;
    text-transform: capitalize;
  }
  
  .ent-cons-ref {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.25em;
    margin-top: -15px;
  }
  
  .ent-cons-ref p {
    transition: 0.2s;
    padding-bottom: 2px;
  }
  
  .ent-cons-ref:hover p {
    box-shadow: 0px 1px 0px 0px black;
  }
  
  .ent-cons-ref img {
    border-radius: 100%;
    margin-right: 5px;
    object-fit: cover;
  }
  
  .description h3 {
    margin-bottom: 10px;
  }
  
  .description p {
    font-weight: 300;
    font-size: 16px;
  }
  
  .desc {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .desc-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 5px #1b1b1b24;
    align-items: center;
    justify-content: center;
  }
  
  .desc img {
    height: 17px;
    width: 17px;
  }
  
  .recommendations {
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  
  .recommendations h3 {
    margin-bottom: 10px;
  }
  
  .recommendation-container {
    padding: 20px 0;
    width: 100%;
  }
  
  .get-in-touch {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* position: absolute;
    top: 20px;
    right: 20px; */
    text-decoration: none;
  }
  
  .gigs-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0% 0 0 0;
    width: 80%;
  }
  
  .gigs-list>a {
    display: flex;
    /* justify-self: center; */
    /* align-items: center; */
    width: 95%;
    min-width: 260px;
    /* max-width: 400px; */
  }
  
  .row .gigs-column {
    box-sizing: border-box;
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
    /* min-width: 300px !important; */
    /* max-width: 400px !important; */
    background: #f9f9f9;
    aspect-ratio: 16/11;
    border-radius: 20px;
    flex-direction: column;
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    width: 100% !important;
    margin: 0;
    transition: 0.3s;
  }

  .row .gigs-column:hover {
    transform: scale(1.03);
  }
  
  
  .post {
    margin-left: 100%;
    padding: 20px;
    background-color: #fff;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-icon {
    border-radius: 50%;
    background-color: #D9D9D9;
    height: 86px;
    width: 86px;
    padding: 21px 21px;
  }
  
  .post-form img {
    height: 45.5px;
    width: 45.5px;
  }
  
  .post-form p {
    padding: 20px;
    font-weight: 400;
    font-size: 22px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    min-height: 100%;
  }
  
  .cover-image img {
    width: 100%;
    aspect-ratio: 16/9;
  }
  
  .contents {
    width: 100%;
  }
  
  .gigs-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-row-gap: 1.3em;
    grid-column-gap: 1.3em;
    margin: 0 auto;
  }
  @media (max-width: 427px) {
    .gigs-list {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }
  
  .review-title {
    margin: 1.4em;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .summary-container,
  .dashboard-summary {
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }
  
  .summary-section {
    padding-top: 1.5em;
    border-radius: 8px;
  }
  
  .total-revenue-span {
    font-weight: 800;
    font-size: 1.2em;
  }
  
  .revenue-span {
    font-size: 0.8em;
    font-weight: 800;
    color: green;
  }
  
  .header {
    color: red;
    font-size: 1.2em;
    padding-bottom: 0.5em;
  }
  
  .viewmore-button {
    display: block;
    margin-top: 10px;
    color: white;
    font-size: 0.8em;
    padding: 0.5em;
    background: rgb(123, 34, 195);
    background: linear-gradient(45deg, rgba(123, 34, 195, 0.9052871148459384) 65%, rgba(33, 50, 97, 1) 100%);
    border-radius: 5px;
    text-decoration: none;
    width: fit-content;
  }
  
  .summary-section-bubble {
    color: white;
    padding: 1.5em 4em 1em 2em;
    border-radius: 8px;
    /* background: rgb(2, 0, 36);
    background: linear-gradient(18deg, rgba(2, 0, 36, 1) 0%, rgba(51, 51, 173, 1) 0%, rgba(0, 212, 255, 1) 85%); */
    background: rgb(2, 0, 36);
    background: linear-gradient(18deg, rgba(2, 0, 36, 1) 0%, rgba(39, 97, 211, 1) 0%, rgba(40, 102, 185, 1) 100%);
  }
  
  .bubble-header {
    font-size: 1.5em;
    padding-bottom: .3em;
    font-weight: normal;
  }
  
  .bubble-span {
    font-weight: 600;
  }
  
  /* REVIEW BLOCK */
  .review-block {
    display: flex;
    align-self: center;
    padding: 1em 0;
    margin: 0;
    width: 100%;
    margin-top: -1px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    justify-content: space-between;
  }
  
  .review-block>img {
    display: block;
    height: 50px;
    border-radius: 100%;
    aspect-ratio: 1/1;
    margin: 0 10px 0 0;
    /* object-fit: cover; */
  }
  
  .review-block>div {
    flex: 1;
  }
  
  .review-block>div>span>span svg {
    width: 0.6em;
  }
  
  .review-block>div>img {
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .review-block>div>p {
    font-size: 0.8em;
  }
  
  .review-block .review-name {
    font-size: 0.8em;
    font-weight: 500;
  }
  
  .dashboard-container {
    padding-top: 10px;
    display: flex;
    background-color: #f4f4f4;
  }
  
  .dashboard-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 18%;
  }
  
  .dashboard-main {
    width: 85%;
    display: flex;
    flex-direction: column;
    background: rgb(2, 0, 36);
    background: linear-gradient(18deg, rgba(2, 0, 36, 1) 0%, rgba(39, 97, 211, 1) 0%, rgba(10, 87, 190, 1) 96%);
  }
  
  .dashboard-section {
    display: flex;
    flex-direction: row !important;
    margin-top: 2em;
    padding-left: 5em;
  }
  
  .dashboard-summary {
    padding: 2em 0;
    box-shadow: 0 5px 8px #1b1b1b1a;
    background-color: white;
  }
  
  .dashboard-inbox,
  .dashboard-recent {
    width: 100%;
    padding: 1em 2em;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: -1px 23px 25px -5px rgba(0, 0, 0, 0.19);
    margin-bottom: 2em;
    background-color: white;
  }
  
  .sidebar-header {
    border-radius: 5px;
    text-align: center;
    color: white;
    background-color: #0952b3;
    padding: 0.8em 0.8em;
    font-size: 1em;
    width: 70%;
    margin-top: 1em;
    margin-left: 1em;
  }
  
  .sidebar-a {
    margin: 2em 2em;
    color: black;
    font-weight: 600;
    font-size: 0.9em;
    display: block;
    text-decoration: none;
  }
  
  .sidebar-customer {
    margin: 0 1.6em;
  }
  
  .customer-header {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .customer-p {
    font-size: 0.8em;
  }
  
  .customer-button {
    margin: 20px 0;
    margin-bottom: 50px;
    background-color: #0952b3;
    border-radius: 5px;
    color: white;
  }
  
  .customer-terms {
    font-size: 0.8em;
    display: inline-block;
    text-decoration: none;
  }
  
  .recent-p,
  .inbox-p {
    display: block;
    margin: 0.4em 0 0 0;
    margin-top: 1em;
    font-size: 0.9em;
    padding-bottom: 0.4em;
    border-bottom: 1px solid whitesmoke;
  }
  
  .view-span {
    font-size: 0.8em;
    text-decoration: none;
  }
  
  .recent-p {
    display: inline-block;
  }
  
  .inbox-top,
  .recent-top {
    display: flex;
    justify-content: space-between;
    font-size: 1em;
  }
  
  .recent-button {
    font-size: 0.6em;
    background-color: darkorange;
    color: white;
    border-radius: 10px;
    margin-left: 1em;
  }
  
  .dashboard-chart {
    padding: 1em;
    width: 50%;
    height: 93%;
    margin: 0 4em;
    border: 1px solid blue;
    background: white;
    border-radius: 10px;
    box-shadow: -1px 23px 25px -5px rgba(0, 0, 0, 0.19);
  }
  
  @media(max-width: 1280px) {
    .prof-container1 {
      width: 100%;
    }
  
    .prof-container1 .profile-image {
      width: 150px;
      height: 150px;
    }
  
    .prof-container1 .profile-image img {
      width: 140px;
      height: 140px;
    }
  
    .username {
      font-size: 1em;
    }
  
    .joined-active-recommended .link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .joined-active-recommended .link .ent-cons-ref {
      font-size: 1.15em;
      text-align: center;
    }
  
    .description .desc h3 {
      font-size: 1em;
    }
  
    .description p {
      text-align: left;
      align-self: flex-start;
    }
  
    /* .gigs-list{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
    } */
  
    .gigs-column {
      width: 300px !important;
    }
  
    .gigs-list>a {
      display: flex;
      justify-self: center;
      align-items: center;
      width: 90%;
      /* min-width: 300px; */
    }
  
    .dashboard-sidebar {
      padding: 0;
    }
  
    .sidebar-a {
      font-weight: normal;
    }
  
    .summary-section,
    .dashboard-summary {
      padding: 1em 0;
    }
  
    .dashboard-section {
      margin-top: 1em;
      padding-left: 3em;
    }
  
    .inbox-p,
    .recent-p {
      font-size: 0.7em;
    }
  
    .recent-button {
      font-size: 0.5em;
      padding: 0.8em;
    }
  
  }
  
  @media(max-width: 768px) {
    .row {
      width: 100%;
      margin: 20px 0 0 0;
      padding: 0;
    }
  
    .prof-container1 {
      width: 90%;
    }
  
    .gigs-content {
      /* margin: 40px 0; */
      width: 100%;
    }
  
    .dashboard-container {
      flex-direction: column;
    }
  
    .dashboard-sidebar {
      width: 100%;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  
    .sidebar-customer {
      margin: 0;
    }
  
    .sidebar-a {
      margin: 0;
      font-size: 0.8em;
    }
  
    .customer-p,
    .customer-terms {
      display: none;
    }
  
    .customer-header {
      font-size: 0.8em;
      font-weight: normal;
      margin: 0
    }
  
    .customer-button {
      font-weight: normal;
      font-size: 0.6em;
      margin: 0;
    }
  
    .sidebar-header {
      width: fit-content;
      margin: 0;
      padding: 0.6em;
      font-size: 0.8em;
      font-weight: normal;
    }
  
    .dashboard-main {
      width: 100%;
    }
  
    .dashboard-summary {
      width: 100%;
    }
  
    .header,
    .total-revenue-span {
      font-size: 1em;
    }
  
    .summary-section-bubble {
      font-size: 0.8em;
      padding: 1em 2em;
    }
  
    .bubble-span {
      font-size: 1.2em;
    }
  
    .dashboard-section {
      padding-left: 0.5em;
      justify-content: space-evenly;
    }
  
    .dashboard-chart {
      margin: 0;
    }
  
    /* 
    .gigs-list{
      display: flex !important;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding: 0 10px;
      margin: 0;
    } */
  
    .gigs-list>a {
      width: min(80%, 450px);
      justify-content: center;
      max-width: none;
    }
  
    .gigs-list .GigThumbnail {
      width: 100%;
    }
  
    .col {
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0;
    }
  }
  
  @media (max-width: 430px) {
  
    .dashboard-sidebar {
      display: inline-block;
    }
  
    .sidebar-a {
      display: inline-block;
      font-size: 1em;
      margin: 0.8em 1em;
      font-weight: bold;
    }
  
    .sidebar-customer {
      display: inline-block;
    }
  
    .customer-header {
      font-weight: bold;
    }
  
  
    .sidebar-header {
      display: none;
    }
  
    .summary-section-bubble {
      padding: 1em;
    }
  
    .bubble-header {
      font-size: 1em;
    }
  
    .header {
      padding: 0;
    }
  
    .revenue-span {
      display: block;
    }
  
    .dashboard-section {
      display: block;
      padding: 0;
    }
  
    .inbox-p,
    .recent-p {
      font-size: 0.9em;
    }
  
    .dashboard-inbox,
    .dashboard-recent {
      padding: 1em;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1em;
    }
  
    .dashboard-chart {
      width: 90%;
      height: 260px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }