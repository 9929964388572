:root {
    --gray-background: #ebeef1;
    --nav-background: #f9f9f9;
    --blue-default: #0952b3;
    --std-radius: 20px;
    /* --std-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
    --std-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);;
    --std-delta-time: 0.3s;
}

.row {
    display: flex;
    /* justify-content: center; */
    padding: 0 var(--margin);
    margin: 40px 0 0 0;
    align-items: flex-start;
    gap: 3em;
}



/* 
.__          _____  __                          __  .__               
|  |   _____/ ____\/  |_    ______ ____   _____/  |_|__| ____   ____  
|  | _/ __ \   __\\   __\  /  ___// __ \_/ ___\   __\  |/  _ \ /    \ 
|  |_\  ___/|  |   |  |    \___ \\  ___/\  \___|  | |  (  <_> )   |  \
|____/\___  >__|   |__|   /____  >\___  >\___  >__| |__|\____/|___|  /
\/                   \/     \/     \/                    \/ 
*/

.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2em;
    width: 50%;
}

.row .col:nth-child(1) {
    max-width: 375px;
}

/* 
       .__       .__     __                          __  .__               
_______|__| ____ |  |___/  |_    ______ ____   _____/  |_|__| ____   ____  
\_  __ \  |/ ___\|  |  \   __\  /  ___// __ \_/ ___\   __\  |/  _ \ /    \ 
 |  | \/  / /_/  >   Y  \  |    \___ \\  ___/\  \___|  | |  (  <_> )   |  \
 |__|  |__\___  /|___|  /__|   /____  >\___  >\___  >__| |__|\____/|___|  /
         /_____/      \/            \/     \/     \/                    \/ 
*/

.row .right-col {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 2em;
}

.row .right-col>div {
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: var(--gray-background);
    border-radius: var(--std-radius);
    /* height: 100%; */
    box-shadow: var(--std-shadow);
    padding: 2em var(--margin);
}


/* 
                                                   .__              
_______   ____   ____________   ____   ____   _____|__|__  __ ____  
\_  __ \_/ __ \ /  ___/\____ \ /  _ \ /    \ /  ___/  \  \/ // __ \ 
 |  | \/\  ___/ \___ \ |  |_> >  <_> )   |  \\___ \|  |\   /\  ___/ 
 |__|    \___  >____  >|   __/ \____/|___|  /____  >__| \_/  \___  >
             \/     \/ |__|               \/     \/              \/ 
*/

@media(max-width: 768px) {
    .row {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    .col {
        justify-content: flex-start;
        align-items: center;
        max-width: none !important;
        width: 100%;
        padding: 0;
        gap: 20px;
    }

    .col>div,
    .col>.right-col>div {
        border-radius: 0 !important;
    }

}