.popup-container {
    position: fixed;
    width: 25em;
    height: 15em;
    font-weight: 600;
    font-size: large;
    background-color: #f9f9f9;
    border-radius: 1em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 2000;

}
.popup-text > img {
    height: 60px;
    margin-bottom: 15px;
}

.background-overlay {
    background-color: rgba(128, 128, 128, 0.785) !important;
    backdrop-filter: blur(2px);
    position: fixed !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;

    border-radius: 0 !important;
    z-index: 1000;

}

@media screen and (max-width: 1000px ) {
    .popup-container {
        width: 20em;
        font-size: medium;
        height: 12em;
    }

    .popup-text > img {
        height: 40px;
    }
}

@media screen and (max-width: 750px) {
    .popup-container {
        width: 15em;
        height: 10em;
        font-size: small;
    }
    .popup-text > img {
        height: 30px;
    }
}