.gig-setup-page {
    width: 10%;
}

.gig-setup-cancel {
    background-color: #FB0000;
    margin-top: 1em;
    padding: 0.5em;
    padding-left: 0.9em;
    cursor: pointer;
    color: white;
    font-size: 0.8em;
    border-radius: 0.5em;

}

.gig-setup-layout {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 25em;
    flex-shrink: 0;
    background: linear-gradient(92deg, #D9D9D9 -176.76%, #9EB4D2 -98.36%, #0857C0 101.45%);
}

.gig-setup-layout-header {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    /* background: linear-gradient(271deg, #1B1B1B 54.57%, #0857C0 108.1%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.gig-setup-layout-subheader {
    color: #E0E0E0;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 300;
    line-height: 121.452%;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    /* 29.149px */
}

.gig-setup-layout-paragraph {
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 121.452%;
    /* 18.218px */
    letter-spacing: 0.15px;
}

.gig-setup-layout-form {
    display: flex;
}

body {
    overflow-x: hidden;
}

.slider {
    box-sizing: border-box;
    display: flex;
    /* width: 100%; */
    height: 100%;
    transition: 1s;
}

.gigs-list a {
    text-decoration: none;
}

@media screen and (max-width: 1025px) {
    .gig-setup-layout {
        padding-left: 10em;
    }
}

@media screen and (max-width: 426px) {
    .gig-setup-layout {
        width: 100%;
        padding-left: 1.8em;
    }

    .gig-setup-cancel {
        width: 100px;
    }
}