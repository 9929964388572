:root {
    --primary-background: #fff8f7;
    /* --primary-background: #fff1f6; */
    --secondary-background: #fffefe;
}

/* html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
} */

.homepage-main {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: var(-- background) !important; */
}

/* .homepage-category {
    display: flex;
    justify-content: space-between;
    background-color: white;
}


.category-nav {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 10px 20vh;
} */
.homepage-category {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    /* background-color: white; */
    /* padding: 25px 10px 0px 10px; */
}

@media (max-width: 768px) {
    .homepage-category {
        display: none;
    }
}

.category-nav {
    display: flex;
    /* background: #f9f9f9;
    padding: 0px 40px; */
    /* border-radius: 3em; */
    /* gap: 20px; */
}

.category-nav .link {
    /* flex: 1; */
    width: 230px;
    text-transform: uppercase;
    background: #f9f9f9;
    color: #1B1B1B;
    height: 100%;
    padding: 10px 15px;
    text-align: center;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    transition: all 0.5s;
    box-shadow: #d9d9d9 1px 2px 4px;
}

@keyframes text-hover {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}

.category-nav .link:hover {
    background: #d9d9d9;
    font-weight: 600;
    animation: text-hover 0.2s;
    background-color: #d1eefe;
}

.category-nav .link:nth-child(1) {
    border-radius: 3em 0 0 3em;
    padding-left: 3em;
}

.category-nav .link:nth-last-child(1) {
    border-radius: 0 3em 3em 0;
    padding-right: 3em;
}

/* 
.__                                                                             __                 __          
|  |__   ____   _____   ____ ___________     ____   ____     ____  ____   _____/  |_  ____   _____/  |_  ______
|  |  \ /  _ \ /     \_/ __ \\____ \__  \   / ___\_/ __ \  _/ ___\/  _ \ /    \   __\/ __ \ /    \   __\/  ___/
|   Y  (  <_> )  Y Y  \  ___/|  |_> > __ \_/ /_/  >  ___/  \  \__(  <_> )   |  \  | \  ___/|   |  \  |  \___ \ 
|___|  /\____/|__|_|  /\___  >   __(____  /\___  / \___  >  \___  >____/|___|  /__|  \___  >___|  /__| /____  >
     \/             \/     \/|__|       \//_____/      \/       \/           \/          \/     \/          \/ 
*/

.homepage-category-account {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    margin-bottom: 60px;
    /* min-height: calc(100vh - 195px); */
    /* justify-content: space-evenly; */
    /* padding: 10% 0; */
}


.account {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    gap: 10%;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;
}

.account>div {
    aspect-ratio: 16/9;
    flex: 1;
    min-width: 300px;
    max-width: 80%;
    margin: 30px 0;
    overflow: hidden;
    position: relative;
}

.account>div>* {
    position: absolute;
    left: 6%;
}

.account-value {
    font-size: 1em;
    font-weight: 600;
}

.account button {
    transform-origin: 50% 50%;
}

.account button:hover {
    animation: text-hover 1s;
}

.balance {
    border-radius: 28px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: #0952B3;
    /* background: url('../assets/blobanimation.svg'); */
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 50vh;
    height: auto;
    gap: 25px;
    box-shadow: #cdcdcd 2px 3px 5px 2px;
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=- BALANCE =-=-=-=-=-=-=-=-=-=-=-=-=-= */
.balance-title {
    top: 10%;
    font-size: 1.4em;
}

.balance-client-account-label {
    top: 35%;
    font-size: 0.7em;
}

.balance-available-balance {
    top: 65%;
    font-size: 1em;
}

.balance-user-name {
    top: 13%;
    left: 76% !important;
    font-size: 0.7em;
    text-transform: capitalize;
}

.balance-value {
    top: 77%;
    display: flex;
}

.balance-value button {
    padding: 0;
    margin: 0 1em;
    background: none;
    color: white;
    font-size: 0.8em;
}

.balance-load-wallet {
    top: 70%;
    left: 67% !important;
    border-radius: 0.4em;
}

.balance-load-wallet>a {
    color: black;
    font-size: 0.6em;
    font-weight: 500;
    text-decoration: none;
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-= */


/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-== TRANSACTIONS =-=-=-=-=-=-=-=-=-=-=-=-= */
.transaction-title {
    top: 12%;
    font-size: 1em;
    font-weight: 600;
}

.transaction-recharge-label {
    top: 39%;
    font-size: 0.8em;
    font-weight: 400;
}

.transaction-recharge {
    top: 48%;
}

.transaction-completed-label {
    top: 68%;
    font-size: 0.8em;
    font-weight: 400;
}

.transaction-completed {
    top: 77%;
}

.export-statements {
    top: 75%;
    left: 60% !important;
    display: flex;
    align-items: center;
    margin: 0 1em;
    gap: 0.3em;
    border-radius: 0.4em;
    font-size: 0.5em;
    font-weight: 500;
    border: 1px solid #1B1B1B;
    background: #69FF51;
    color: #1B1B1B;
}

.export-statements img {
    height: 0.6em;
}

/* .transaction-recharge */
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-= */

.account-eye {
    padding: 0;
    margin: 0 1em;
    background: none;
    color: white;
    font-size: 0.8em;
}

.transactions p,
.balance h1,
.transaction-header h1,
.balance p,
.shortcut p,
.home-gig-card h2,
.home-gig-card p {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.transactions {
    background-color: #1B1B1B;
    ;
    color: #fff;
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    border-radius: 12px;
    width: 50vh;
    height: auto;
    gap: 35px;

    box-shadow: #cdcdcd 2px 3px 5px 2px;
}

/* 
.recharge {
    color: rgb(85, 225, 85);
}

.completed {
    color: rgb(203, 58, 58);
} */


/* 
       .__                   __                 __          
  _____|  |__   ____________/  |_  ____  __ ___/  |_  ______
 /  ___/  |  \ /  _ \_  __ \   __\/ ___\|  |  \   __\/  ___/
 \___ \|   Y  (  <_> )  | \/|  | \  \___|  |  /|  |  \___ \ 
/____  >___|  /\____/|__|   |__|  \___  >____/ |__| /____  >
     \/     \/                        \/                 \/ 
*/
.shortcuts {
    display: flex;
    flex-direction: row;
    /* margin: 10vh auto; */
    width: 100%;
    height: 80px;
    justify-content: center;
    text-align: center;
    /* background: var(--secondary-background); */
    background: linear-gradient(270deg, #F3F2F5 0%, rgba(243, 242, 245, 0.00) 100%);
    /* stroke-width: 5px;
    stroke: #FFF; */
    /* padding: 100px; */
}

.my-shortcuts-label {
    display: flex;
    align-items: center;
    min-width: 500px;
    flex: 1.5;
}

.my-shortcuts-label-block h1 {
    display: flex;
    gap: 0.6rem;
    padding: 0;
    margin: 0 20px 0 0;
    text-transform: uppercase;
    font-size: 1.7em;
    font-weight: 600;
}

.my-shortcuts-label-block p {
    font-weight: 200;
}

.my-shortcuts-label-block {
    background: black;
    display: flex;
    align-items: center;
    justify-content: right;
    color: white;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.my-shortcuts-label-triangle {
    background: black;
    height: 70.71%;
    aspect-ratio: 1/1;
    transform-origin: 50% 50%;
    transform: translate(-50%) rotateZ(45deg);
    z-index: 2;
}

.shortcut {
    display: flex;
    width: 130px;
}

.shortcut>div {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.shortcut-items {
    display: flex;
    justify-content: center;
    gap: 3em;
    flex: 3;
}

/* @media (max-width: 470px) {
    .shortcut-items {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
} */

.shortcut .shortcut-icon-div {
    /* padding: 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0952B3;
    border-radius: 50%;
    height: 60%;
    aspect-ratio: 1/1;
    border: 0.15em solid white;
    box-shadow: #0952B3 0 0 0 0.15em;
    transition: 0.2s;
    font-weight: 400;
}

.shortcut .shortcut-icon-div:hover {
    background: #084698;
    box-shadow: #084698 0 0 0 0.15rem;
    animation: text-hover 0.2s;
}

.shortcut img {
    height: 50%;
    aspect-ratio: 1/1;
}




@media (max-width: 1064px) {
    .category-nav .link {
        font-size: 0.7rem;
        width: 200px;
    }

    .account {
        font-size: 0.85em;
        gap: 5%;
    }

    .shortcuts {
        height: 65px;
        /* font-size: 0.95rem; */
        gap: 3em;
        font-size: 0.8rem;
    }

    .my-shortcuts-label {
        flex: 2;
        min-width: 0;
    }

    .shortcut-items {
        gap: 10px;
        justify-content: center;
    }
}

@media (max-width: 715px) {
    .shortcut p {
        display: none;
    }

    .shortcut {
        width: auto;
    }

    .shortcuts {
        justify-content: space-between;
        gap: 0;
    }

    .my-shortcuts-label-block {
        padding: 0 10px;
    }

    .my-shortcuts-label {
        max-width: 300px;
    }

    .shortcut-items {
        flex: 0;
        gap: 3em;
        justify-content: right;
        margin-right: 3em;
    }
}

@media (max-width: 520px) {
    .shortcuts {
        flex-direction: column;
        height: auto;
        padding: 2em 0;
    }

    .my-shortcuts-label {
        text-align: center;
        flex: 1;
        max-width: 100%;
        width: 100%;
        justify-content: center;
        margin: 2em 0;
    }

    .my-shortcuts-label-triangle {
        display: none;
    }

    .my-shortcuts-label-block {
        background: transparent;
        color: black;
        justify-content: center;
    }

    .shortcut-items {
        justify-content: center;
        margin: 0;
        height: 300px;
    }

    .my-shortcuts-label h1 p {
        font-size: inherit;
        font-weight: inherit;
    }
}

/*
                                    __                                .__                      
_______   ____   ____  ____   _____/  |_    ______ ____   ______ _____|__| ____   ____   ______
\_  __ \_/ __ \_/ ___\/ __ \ /    \   __\  /  ___// __ \ /  ___//  ___/  |/  _ \ /    \ /  ___/
 |  | \/\  ___/\  \__\  ___/|   |  \  |    \___ \\  ___/ \___ \ \___ \|  (  <_> )   |  \\___ \ 
 |__|    \___  >\___  >___  >___|  /__|   /____  >\___  >____  >____  >__|\____/|___|  /____  >
             \/     \/    \/     \/            \/     \/     \/     \/               \/     \/ 
*/

.recent-sessions-wrapper {
    display: flex;
    margin-bottom: 40px;
    background-color: whitesmoke;
    padding: 2em 0;
    width: 100%;
}

.recent-sessions {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: gray; */
}

.calendar-container {
    width: 500px;
    border: 1px solid black;
}

.sessions-container {
    display: flex;
    width: 100%;
}

.ent-row {
    width: 100%;
    display: flex;
    flex: 1;
    scrollbar-width: none;
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.ent-row::-webkit-scrollbar {
    display: none;
}

.arrow-rotate-right {
    transform: rotate(180deg);
}

.session-row-inner-div {
    display: flex;
    justify-content: center;
}

.session-row-inner-div:last-child {
    border-top: 1px solid gainsboro;
}

.data-nav-button {
    background-color: transparent;
}

.recent-nav-btn {
    display: flex;
    justify-content: center;
}

.homepage-section-header {
    color: #002147;
    /* text-align: center; */
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1em;
    margin-left: 3.5em;
}
