.slider-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.slider-children {
    display: flex;
    flex: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-snap-type: x mandatory;
}
.slider-children::-webkit-scrollbar {
    display: none;
}


.slider-screen {
    display: flex;
}

.slider-screen > * {
    scroll-snap-align: start;
}




.slider-right-button, .slider-left-button {
    /* width: 3e; */
    background: transparent;
    padding: 0;
    margin: 0;
    height: fit-content;
    width: calc(var(--margin) - 10px);
}

.slider-right-button:disabled, .slider-left-button:disabled {
    filter: invert(0.6);
}
.slider-right-button:hover, .slider-left-button:hover {
    filter: invert(0.6);
}


.slider-right-button img, .slider-left-button img {
    width: 0.9em;
}

@media only screen and (max-width: 800px) {
    .slider-left-button, .slider-right-button {
        margin: 0;
        padding: 0;
    }   
}