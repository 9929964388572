.GigThumbnail {
    box-shadow: var(--std-shadow);
    background-color: #F9F9F9;
    width: 340px;
    height: 251px;
    font-size: 50%;
    border-radius: 20px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: var(--std-delta-time);
}
.GigThumbnail:hover {
    transform: scale(1.02);
}

.gig-informations {
    display: flex;
    justify-content: space-between;
}

.GigThumbnail > img {
    aspect-ratio: 16/9;
    object-fit: cover;

}

.GigThumbnail h2 {
    font-size: 15px;
    color: #1b1b1b;
    text-align: center;
    font-weight: 400;
}

.GigThumbnail h4 {
    font-size: 12px;
    font-weight: 300;
    color: #1b1b1b;
    text-align: center;
}


.GigThumbnail h3 {
    font-size: 12px;
    font-weight: 300;
    color: #1b1b1b;
    text-align: center;
}

.GigThumbnail *:not(img) {
    padding: 1px 5px;
    margin: 0;
}

.view-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4em;
}

.gig-thumbnail-left {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
}

.thumbnail-profile-pic {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    object-fit: cover;
}

.edit-link button {
    text-align: right;
    font-size: 1em;
    background: #0952B3;
    color: white;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 0.3em;
    transition: 0.3s;
}

.edit-link button:hover {
    background: rgb(49, 151, 235);
}