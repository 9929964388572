/*
    ███████ ███████ ███████ ███████ ██  ██████  ███    ██     ██ ███    ██ ███████  ██████  
    ██      ██      ██      ██      ██ ██    ██ ████   ██     ██ ████   ██ ██      ██    ██ 
    ███████ █████   ███████ ███████ ██ ██    ██ ██ ██  ██     ██ ██ ██  ██ █████   ██    ██ 
         ██ ██           ██      ██ ██ ██    ██ ██  ██ ██     ██ ██  ██ ██ ██      ██    ██ 
    ███████ ███████ ███████ ███████ ██  ██████  ██   ████     ██ ██   ████ ██       ██████  
*/
.user-session-description-wrapper {
    position: sticky;
    top: 0px;
    background: whitesmoke;
    width: 100%;
    margin: 0;
    padding: 2em var(--margin);
    z-index: 10;
}
.user-session-description-wrapper h1 {
    margin: 1em 0;
    /* font-weight: bold; */
}
.user-session-description-container {
    display: flex;
    width: 100%;
}
.user-session-description-div {
    flex: 1;
    background: var(--gray-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    gap: 1em;
    text-align: center;
    position: relative;
    box-shadow: 1px 1px 4px gray;
    transition: 0.3s;
}
.user-session-description-div:first-child {
    border-radius: 2em 0 0 2em;
}
.user-session-description-div:last-child {
    border-radius: 0 2em 2em 0;
}

.user-session-description-div:hover {
    transform: scale(1.03);
    z-index: 100;
}

.user-session-description-div h2 {
    color: #002147;
    font-size: 1.1em;
    font-weight: 500;
}
.user-session-description-div p {
    color: #0952b3;
    font-size: 1.6em;
    /* padding-top: 0.4em; */
}



.recent-sessions-wrapper {
    scroll-margin-top: 150px;
}

.recommended-gig-header-session {
    margin-left: var(--margin);
}

.user-session-footer .container {
    display: none;
}   

@media screen and (max-width: 900px) {
    .user-session-description-div {
        flex-direction: column-reverse;
        gap: 0;
        padding: 1em 0;
    }
}
@media screen and (max-width: 800px) {
    .recommended-gig-header-session {
        margin-left: 2.3em;
    }
}

@media screen and (max-width: 560px) {
    .user-session-description-wrapper {
        position: static;
        height: calc(100vh - 90px);
    }
    .user-session-description-container {
        flex-direction: column;
        gap: 0.5em;
    }
    .user-session-description-div {
        flex-direction: row;
        gap: 1em;
        padding: 1em 3em;
    }
    .user-session-description-div {
        border-radius: 0 !important;
    }
    /* .user-session-description-div {
        gap: 0;
        padding: 1em 0;
    } */
    /* .user-session-description-div h2 {
        font-size: 0;
    }
    .user-session-description-div h2::first-letter {
        font-size: 30px;
    } */
    .user-session-description-div p {
        font-size: 1.3em;
        font-weight: bold;
    }
    .user-session-description-div h2 {
        font-weight: bold;
    }

    
    .recent-sessions-wrapper {
        scroll-margin-top: 50px;
    }
}

@media (max-width: 375px) {
    .recommended-setion-div {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr))
    }
}