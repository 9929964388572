.footer-base-1 {
    background-color: #0952b3 !important;
    font-size: 0.9em;
    margin-top: 5em;
    padding: 0 var(--margin);
}

.footer-contents {
    display: flex;
    flex-direction: column;
    gap: 4em;
    max-width: 40em;
}

.footer-base-1 .container {
    padding: 80px 0px;
}

.footer-base-1>.content>.container>.footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
}

.footer-base-1>.content>.container>.footer-row>div {
    text-align: left;
}

.footer-base-1>.content>.container>.footer-row>div:last-child {
    max-width: 23em;
    min-width: 18em;
    aspect-ratio: 1/1;
}

.footer-base-1>.content>.container>.footer-row img {
    display: block;
    width: 13em;
    margin: 10px 0;
    border-radius: 1.3em;
}

.footer-base-1>.content>.container>.footer-row h1 {
    color: white;
    font-weight: 450;
    font-size: 1.7em;
}


.footer-base-1>.content>.footer2-row.copy-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    min-height: 3em;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}


.footer-copyright-message {
    width: fit-content;
    color: gainsboro;
    flex: 1;
}

.footer-copyright-message::after {
    content: ' Tackoom Digital Private Limited';
}

.footer-base-1>.content>.footer2-row.copy-row>.btn-group.social-group.btn-group-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
}

.footer-base-1 p,
.footer-base-1 span {
    margin-top: 20px;
    font-size: 0.9em;
    color: gainsboro;
}



.btn-group.social-group.btn-group-icons a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    aspect-ratio: 1/1;
    color: gainsboro;
    border: 1px solid gainsboro;
    border-radius: 50%;
    margin: 0 0.9em;
    transition: 0.3s;
}

.btn-group.social-group.btn-group-icons a svg {
    width: 1em;
    height: 1em;
    aspect-ratio: 1/1;
}

.btn-group.social-group.btn-group-icons a:hover {
    border: 1px solid #9daac7;
    background: rgb(38, 86, 123);
    color: whitesmoke;
    border: 1px solid whitesmoke;
    transform: scale(1.03);
}

.footer-map-container {
    flex: 1;
    width: 50%;
    height: 100%;
}

.footer-map {
    width: 100%;
    height: 100%;
    border-radius: 3em;
}


@media screen and (max-width: 769px) {
    .footer-base-1 .container {
        padding: 50px 0px;
    }

    .footer-base-1 p,
    .footer-base-1 span {
        margin-top: 20px;
        font-size: 1em;
        color: #9daac7;
    }

}

@media screen and (max-width: 376px) {
    .footer-base-1 .container {
        padding: 40px 0px;
    }

    .footer-base-1>.content>.container>.footer-row img {
        display: block;
        width: 12em;
        margin: 10px 0;
        border-radius: 1.3em;
    }

    .footer-base-1>.content>.container>.footer-row h1 {
        color: white;
        font-weight: 450;
        font-size: 1.6em;
    }
    .footer-base-1>.content>.container>.footer-row>div:last-child {
        min-width: 0;
    }
}

@media screen and (max-width: 600px) {
    .footer-map-container {
        width: 100%;
        max-width: 100% !important;
    }

    .footer-copyright-message::after {
        content: ' tackoom';
    }
}