@media screen and (max-width: 1700px) {
    .gif {
        margin-top: 2rem;
        width: 99%
    }
}


@media screen and (max-width: 900px) {
    .gif {
        margin-left: 10%;
        width: 70%
    }
}

@media screen and (max-width: 550px) {
    .gif {
        margin-left: 1%;
        width: 90%
    }
}