.logo-nav {
    position: sticky;
    top: 0;
    display: flex;
    vertical-align: middle;
    height: 80px;
    width: 100%;
    justify-content: space-between;
    /* outline: 2px solid black; */
    /* box-shadow: 0px 0px 5px; */
    z-index: 1000;
    background: var(--nav-background);
    gap: 26px;
    padding: 0 26px;
    transition: 0.2s;
}

.logo-header {
    margin: 10px;
    color: #0952b3;
}

.logo-nav a {
    text-decoration: none;
}

.navbar-bg-color {
    position: fixed;
    top: 0;
    width: 100%;
    height: 0px;
    z-index: 100;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(6px);
    transition: 0.1s;
}

.navbar-scrolled-down {
    background: rgba(255, 255, 255, 0.9);
    height: 70px;
}

.logo-nav>div {
    display: flex;
    vertical-align: middle;
}

.hamburger {
    width: 28px;
}

.home-link-logo {
    display: flex;
    align-items: center;
}

.logo {
    cursor: pointer;
    height: 50px;
    width: auto;
    border-radius: 100%;
}

.logo-nav button {
    padding: 0;
    background: none;
    outline: none;
}

.navigation-button .new-message-counter-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    height: fit-content;
    bottom: -30%;
    right: -10%;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    font-size: 0.6rem;
    color: white;
    z-index: 100;
}

.navigation-button {
    position: relative;
}

/*
                                   .__     
  ______ ____ _____ _______   ____ |  |__  
 /  ___// __ \\__  \\_  __ \_/ ___\|  |  \ 
 \___ \\  ___/ / __ \|  | \/\  \___|   Y  \
/____  >\___  >____  /__|    \___  >___|  /
     \/     \/     \/            \/     \/ 
*/
.navbar-middle-search {
    flex: 1;
    align-items: center;
    display: none !important;
}


/*
       .__       .__     __    ___.           __    __                        
_______|__| ____ |  |___/  |_  \_ |__  __ ___/  |__/  |_  ____   ____   ______
\_  __ \  |/ ___\|  |  \   __\  | __ \|  |  \   __\   __\/  _ \ /    \ /  ___/
 |  | \/  / /_/  >   Y  \  |    | \_\ \  |  /|  |  |  | (  <_> )   |  \\___ \ 
 |__|  |__\___  /|___|  /__|    |___  /____/ |__|  |__|  \____/|___|  /____  >
         /_____/      \/            \/                              \/     \/ 
*/

.navigation-button {
    display: flex;
    align-items: center;
}

.navbar-right-buttons {
    gap: 1.5em;
}

.navigation-button svg {
    font-size: 23px;
    color: #0952b3;
}

.navigation-button svg:hover,
.profile-drop-down-profile-div:hover {
    transform: scale(1.03);
}

.navigation-session-empty-img {
    width: 26.5px;
}

.navbar-right-buttons button {
    transition: 0.3s;
}

/* .navbar-right-buttons button:hover {
    background: lightblue;
} */

.navbar-right-buttons img {
    /* height: 35px;
    width: 35px; */
    /* height: 150px; */
    /* border-radius: 50%; */
    object-fit: cover;
    cursor: pointer;
    /* aspect-ratio: 1; */
    /* border: 2px solid #0952b3; */
}

.navbar-profile-pic {
    border-radius: 50%;
    width: 42px !important;
    aspect-ratio: 1;
    border: 2px solid #0952b3;
}


.navigation-sign-in {
    display: block;
    color: white;
    text-decoration: none;
    background-color: #0952b3;
    padding: 10px 20px;
    border-radius: 15px;
    transition: 0.3s;
}

.navigation-sign-in:hover {
    transform: scale(1.03);
    /* color: white;
    text-decoration: none;
    background-color: #d7d7d7;
    color: #1b1b1b; */
}



/* =-=-=-=-=-=-=- PROFILE DROPDOWN =-=-=-=-=-=-=-=-=-= */
.profile-drop-down {
    display: none;
    width: 260px;
    height: auto;
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 10;
    border: none;
    /* border-bottom-left-radius: 5%; */
    /* border-bottom-right-radius: 5%; */
    border-radius: 0 0 5% 5%;
    padding: 15px;
    background: #f9f9f9;
    animation: profile-drop 0.1s ease-in-out;
    border: 1px solid #d7d7d7;
}

@keyframes profile-drop {
    0% {
        opacity: 0;
        height: 0;

    }

    100% {
        opacity: 1;
        height: auto;

    }
}

.profile-drop-down>* {
    display: block;
    margin: 1em 0;
    background: #f9f9f9;
}

.profile-drop-down a {
    text-decoration: none;
    /* border-left: 1px solid black; */
    /* padding-left: 0.5em;s */
    color: #1b1b1b;
    transition: 0.3s;
}

.profile-drop-down-profilepic-a {
    text-decoration: none;
    border-left: 1px solid black;
    padding-left: 0.5em;
    color: #1b1b1b;
}

.profile-drop-down-profile-div {
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
}

.profile-drop-down img {
    width: 2.6em;
    height: 2.6em;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-right: 0.6em;
    border: 2px solid #0952b3;
}

.profile-drop-down-session-icon {
    color: gray;
}

.profile-drop-down-logout-icon {
    font-size: 1em !important;
    margin-right: 10px;
    color: white !important;
}
.profile-drop-down-settings-icon {
    font-size: 1em !important;
    margin-right: 10px;
    color: #0952b3 !important;
}

.profile-drop-down-username {
    color: #1b1b1b;
    font-size: 1em;
}

.profile-drop-down-source {
    color: gray;
    font-size: 0.9em;
    text-transform: capitalize;
}

.profile-drop-down-menu * { transition: 0.3s; }
.profile-drop-down-menu > button {
    cursor: pointer;
    transition: 0.3s;
    color: #0952b3;
    font-weight: 700;
    width: 50%;
    font-size: 1em;
    padding: 0.2em 0.9em;
    border-radius: 8px;
    background: white;
    box-shadow: var(--std-shadow);
}
.profile-drop-down-menu > button:hover {
    background: #0952b3;
    color: whitesmoke;
    font-weight: 600;
    transform: scale(1.03);
}
.profile-drop-down-menu > button:hover * {
    color: whitesmoke;
}
.profile-drop-down>*:hover {
    background: #f9f9f9;
}

.profile-drop-down-auth {
    margin: 0.5em 0;
}

.profile-drop-down-auth button {
    background-color: #0952b3;
    /* opacity: 50%; */
    width: 50%;
    padding: 0.2em 0.9em;
    border-radius: 8px;
    font-weight: 700;
    color: whitesmoke;
    transition: 0.3s;
}
.profile-drop-down-auth button * {
    transition: 0.3s;
}

.profile-drop-down-auth button:hover {
    background: white;
    color: #0952b3;
    box-shadow: var(--std-shadow);
    font-weight: 600;
    transform: scale(1.03);
}
.profile-drop-down-auth button:hover * {
    color: #0952b3;
}

.greeting {
    margin-left: 20%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 910px) {
    .greeting p {
        display: none;
    }
}

@media (max-width: 785px) {
    .greeting {
        margin-left: 0;
        justify-content: initial;
        font-size: 13px;
    }
}

@media (max-width: 440px) {
    .greeting {
        margin-left: 0;
        justify-content: initial;
        font-size: 11px;
    }
    .logo-header {
        display: none;
    }
}

@media (max-width: 350px) {
    .navbar-right-buttons button {
        margin-right: 0;
    }

    .logo-nav {
        gap: 10px;
    }
}

.greeting img {
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */

.navbar-bg-filler {
    position: absolute;
    width: 100vw;
    height: 200px;
    z-index: -1;
    background: linear-gradient(180deg, #ECECEC 0%, rgba(217, 217, 217, 0.00) 100%);
}

/* .nav-filler {
    min-height: 80px;
} */


/*
    ███    ██  ██████  ████████ ██ ███████ ██  ██████  █████  ████████ ██  ██████  ███    ██ 
    ████   ██ ██    ██    ██    ██ ██      ██ ██      ██   ██    ██    ██ ██    ██ ████   ██ 
    ██ ██  ██ ██    ██    ██    ██ █████   ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██ 
    ██  ██ ██ ██    ██    ██    ██ ██      ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██ 
    ██   ████  ██████     ██    ██ ██      ██  ██████ ██   ██    ██    ██  ██████  ██   ████ 
*/
.navbar-notification-container {
    display: flex;
    position: absolute;
    top: 80px;
    right: 0;
    flex-direction: column;
    background: white;
    max-height: 50vh;
    width: 50vw;
    overflow: auto;
    border: 1px solid #d7d7d7;
    border-radius: 0 0 5% 5%;
}

.navbar-notification-div {
    display: flex;
    text-align: left;
    border-bottom: 1px solid gainsboro;
    padding: 1em 1em 1em 0.3em;
}

.navbar-notification-div h3 {
    font-size: 0.9em;
    text-align: center;
    margin-left: 2em;
}

.navbar-notification-icon {
    display: flex;
    width: 100px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.navbar-notification-desc h4 {
    font-weight: 600;
    color: #3B3C36;
}

.navbar-notification-desc p {
    font-size: 0.9em;
}

.notification-success {
    color: green;
}

.notification-error {
    color: red;
}

.notification-info {
    color: darkblue;
}

.notification-other {
    color: darkblue;
}








@media (max-width: 560px) {
    .navbar-notification-container {
        position: fixed;
        width: calc(100% - 20px);
        padding: 0 24px;
        left: 0;
    }
    .profile-drop-down {
        position: fixed;
        width: calc(100% - 20px);
        padding: 24px;
        left: 0;
    }
}