h1{
    margin: 0;
    padding: 0;
}

.video-chat-startup{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 80px;
}

.video-chat-startup .mega-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    align-items: center;
    margin: 80px 0 0 0;
}

.video-chat-startup .session-title{
    color: #0952B3;
    margin: 0.5em 0 0em 0;
    font-size: 2.2em;
}

.video-chat-startup .session-gig-title{
    text-transform: capitalize;
    font-size: 22px;
    color: #1b1b1b;
    margin: 0 0 30px 0;

}

.video-container-waiting{
    /* display: flex;
    flex-direction: column; */
    /* justify-content: center;
    align-items: center; */
    width: 55%;
    object-fit: cover;
}

.video-pfp{
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16/9;
}

.video-pfp video{
    transform: rotateY(180deg);
}

.video-chat .local-video-container{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 25%;
    border-radius: 1em;
    object-fit: cover;
    background-color: #1b1b1b;
    z-index: 1;
    aspect-ratio: 16/9 !important;
    transform: rotateY(180deg);
    transition: all 0.3s;

}


.video-chat .local-video-container .video-pfp{
    background-color: #3b3e42;
}

.video-chat .timer{
    color: #fff;
    font-size: 0.8em;   
}


.video-chat-startup .mega-container .local-video-container{
    width: 100%;
    height: auto;
    position: relative;
    bottom: 10px;
    right: 10px;
    object-fit: cover;
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: column;
    align-items: cente;
    justify-content: center;
   
}

.local-video-waiting{
    width: 100%;
    aspect-ratio: inherit;
    object-fit: cover;
    transform: rotateY(180deg);
    border-radius: 1em;
}

.video-chat {
    background: #202124;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}   

.video-chat .video-container{
    position: relative !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    border: none;
    border-radius: 20px;
    z-index: -0;
    height: min(500px, 80vh);
    
}

.video-chat .local-video-transition{
    transition: all 0.2s;

}

.video-chat .streams{
    padding: 0 30px 0 0;
    width: 100%;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: #202124;
}

.video-chat .remote-video-container{
    height: 100%;
    width: auto;
    aspect-ratio: 16/9;
}

.video-container .remote-video-container .video-chat-mute-icon {
    position: absolute;
    height: auto;
    width: 0.9em;
    top: 1.2em;
    right: 1.5em;
    filter: brightness(6);
}

.video-chat .button-container{
    width: 120px;
    background: none;
    border: none;
    position: static;
    z-index: 1;
    margin: 0 0 20px 0;
}

.video-chat .button-container .mic-icon{
    width: 0.8em;
    filter: brightness(100);
}

.video-chat .button-container .video-icon{
    width: 1.2em;
    filter: brightness(100);
}

.video-chat .button-container .chat-icon{
    width: 1.3em;
}

.video-chat .button-container .session-chat-button{
    background-color: #fff;
}

.video-chat .button-container button .hang-up-icon{
    width: 1.3em;
    filter: brightness(100);
}

.video-chat .session-title{
    color: #fff;
}

.video-chat > div {
    background: gray;
    border: 1px solid black;
}

.video-chat .nav-bar {
    display: flex;
    gap: 30%;
    width: 100%;
    background-color: #202124;
    border: none;
    justify-content: space-between;
    padding: 10px 30px;
    align-items: center;
}

.video-chat .button-container label{
    width: 40px;
    height: 40px;
}

.video-chat .button-container button{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E94235;
}

.button-container {
    width: 150px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 1.2em;
}

.button-container div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.button-container input{
    display: none;
}

.button-container label{
    width: 2.75em;
    height: 2.75em;
    border-radius: 100%;
    background-color: #E94235;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.4em 0.3em 0em #1b1b1b61;
}

.video-label-on {
    background: #f9f9f9 !important;
}
.audio-label-on {
    background: #f9f9f9 !important;
}

.video-label-on > img{
   filter: brightness(0) !important;
}
.audio-label-on > img{
    filter: brightness(0) !important;
}

.button-container .video-icon{
    width: 1.2em;
}

.button-container .mic-icon{
    width: .9em;
}

.join-button{
    width: 90px;
    height: 45px;
    background-color: #0952B3;
    color: #fff;
    border-radius: .6em;
    font-size: .9em;
    margin: 20px 0 0 0;
    transition: all 0.2s;
    box-shadow: 0px 4px 10px #1b1b1b54;
}

.join-button:hover{
    background-color: #105bbd;
    transform: translateY(-2px);
}

.join-button:active{
    background-color: #073b80;
    transform: translateY(0px);
}

.video-cta{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
}

@media(max-width: 1000px){
    .video-chat-startup .session-title{
        font-size: 1.9em;
    }

    .video-chat-startup .mega-container{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        gap: 2em;
        align-items: center;
        padding: 0 10px;
        margin: 2em 0 0 0;
    }

    .video-container-waiting{
        width: 100%;
    }

    .video-chat-startup .video-cta{
        width: 100%;
    }

    .button-container label{
        width: 2.75em;
        height: 2.75em;
    }

    .video-container-waiting .button-container {
        width: 150px;
        bottom: 7.5%;
    }

    .video-chat-startup{
        padding: 10px 40px;
    }

    

}

.streams {
    display: flex;
    flex: 1;
}

.messages-wrapper {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 300px;
    margin: 0 20px;
    background: white;
    border-radius: 20px;
    animation-name: chat-slide;
    animation-duration: 2s;
    
}

@media(max-width: 768px){
    .video-container-waiting .button-container {
        width: 125px;
        bottom: 7.5%;
    }

    .video-container-waiting .button-container label{
        width: 2em;
        height: 2em;
    }

    .button-container .video-icon{
        width: 1em;
    }

    .button-container .mic-icon{
        width: .7em;
    }

    .video-chat-startup{
        padding: 10px 20px;
    }

    .join-button{
        width: 80px;
        height: 35px;
        font-size: 15px;
        font-weight: 100;
        margin: 20px 0 0 0;
    }

    .video-cta h2{
        font-size: 22px;
    }

    .video-chat .streams{
        padding: 10px 0;
        flex: 1;
        height: 80%;
    }

    .video-chat .video-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 90% !important;
        padding: 0px 5px;
        gap: 20px;
        height: 100%;
    }

    .video-chat .local-video-container{
        position: static;
        width: 100%;
        border-radius: 20px;
        object-fit: cover;
        background-color: #1b1b1b;
        z-index: 1;
        aspect-ratio: auto;
        height: 40%;
        flex: 0;
        transition: all 0.3s;
    }
    .video-chat .remote-video-container{
        position: static;
        width: 100%;
        border-radius: 20px;
        aspect-ratio: auto;
        object-fit: cover;
        background-color: #1b1b1b;
        z-index: 01;
        flex: 0;
        height: 40%;
        transition: all 0.3s;
    }

    .video-chat .messages-wrapper{
        position: absolute;
        margin: 0 40px;
        width: 85%;
        height: 75%;
    }

}


@keyframes chat-slide{
    0%{
        transform: (translateX(100vw));
    }
    100%{
        transform: translateX(0);
    }
}

.message-container{
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    width: 100%;
    padding: 10px 5px 7px 0;
    border-radius: 20px;
}

.message-container > div{
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    width: 100%;
    padding: 12px 12px;
    border-radius: 20px;
}


.message-container > div::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.164);
	background-color: #f5f5f5;
    border-radius: 100px;
}

.message-container > div::-webkit-scrollbar
{

    border: none;
	width: 5px;
	background-color: #F5F5F5;
}

.message-container > div::-webkit-scrollbar-thumb
{
    background-color: #1b1b1b;
    border-radius: 50px;
}

.message-input-container input{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 50px;
    padding: 0 10px;
    width: 100%;
    border: none;
    border-top: 1px solid #1b1b1b54;
    outline: none;
}

.outgoing-message{
    width: fit-content;
    margin: 3px 0;
    padding: 10px 15px;
    font-size: .65em;
    background-color: #0952B3 !important;
    color: #fff;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    
}

.incoming-message{
    width: fit-content;
    margin: 3px 0; 
    padding: 10px 15px;
    font-size: .65em;
    background-color: #e2e2e2 !important;
    color: #1b1b1b;
    border-radius: 20px;
    border-bottom-left-radius: 0;

}

/* chat button */
.session-chat-button-opened {
    background: white;
}
.session-chat-button-closed {
    background: red;
}

.messages div {
    border: 1px solid black;
}
.incoming-message {
    background: rgb(71, 71, 136);
}
.outgoing-message {
    background: white;
}

.messages-chat-closed{
    display: none;
    
}

.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-color: #202124;
}

.video-container > div {
    position: relative;
}

.video-container > div > video {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}



/* Disable browser-specific effects */
video::-webkit-media-controls {
    display: none; /* Disable controls in Chrome/Safari */
}
video::-webkit-media-controls-enclosure {
    display: none; /* Disable controls in Chrome/Safari */
}
video::-webkit-media-controls-panel {
    display: none; /* Disable controls in Chrome/Safari */
}
video::-moz-media-controls {
    display: none; /* Disable controls in Firefox */
}
html > div[style] {
    display: none !important;
}

.video-pfp {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgb(46, 48, 52);
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    object-fit: cover;
}

.remote-video-container video{
    transform: rotateY(180deg);
    border-radius: 20px;
}

.video-pfp > img {
    height: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;

}


.remote-video-container {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 1em;
    background-color: #1b1b1b;
}



/* 
                   .___                              
  ____   ____    __| _/ ___________     ____   ____  
_/ __ \ /    \  / __ |  \____ \__  \   / ___\_/ __ \ 
\  ___/|   |  \/ /_/ |  |  |_> > __ \_/ /_/  >  ___/ 
 \___  >___|  /\____ |  |   __(____  /\___  / \___  >
     \/     \/      \/  |__|       \//_____/      \/ 
*/
.session-end-page-wrapper {
    background: black;
    width: 100%;
    height: 100%;
    color: white;
}
.session-end-page {
    position: absolute;
    width: 400px;
    height: auto;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 0;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.session-end-page p{
    margin: 5px 0;
}

.session-end-page svg {
    width: 25px !important;
    fill: white;
}

.session-end-page span {
    margin: 2px auto;
}

.session-end-page .session-review {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-around;
    margin: 5px 0;
}

.session-end-page .session-review p{
    font-size: 0.8em;
    margin: 5px;
}


.session-end-page textarea {
    flex: 1;
    background: transparent;
    border-radius: 10px;
    width: 270px;
    height: 70px;
    margin: auto;
    padding: 10px;
    resize: none;
    outline: none;
    color: white;
}

.session-end-page button{
    background-color: #0952B3;
    color: #fff;
    font-size: 0.8em;
    font-size: 300;
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:  10px;
    margin: 10px;
}


.session-ended {
    background: black;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.session-ended h1 {
    font-weight: 200;
    font-size: 1.7em;
}
.session-ended button {
    display: block;
    margin: 2em;
    border-radius: 0.3em;
    transition: 0.2s;
    font-size: 0.6em;
}
.session-ended button:hover {
    background: rgb(233, 233, 233);
}