.recommended-gigs-wrapper {
    padding: 20px 0;
    margin: 0 var(--margin);
}

.recommended-gig-header {
    color: #002147;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 3em 0 1em 0;
}

.recommendation-section-wrapper {
    margin: 2em 0;
}

.recommendation-section-wrapper h3 {
    color: #1B1B1B;
    text-align: center;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 70px 0 50px 0;
}

.recommended-section {
    width: 100%;
    margin: 3em auto;
}

.recommended-setion-div {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-row-gap: 1.3em;
    grid-column-gap: 1.3em;
    /* width: 200px; */
}

/* 
  __  .__                 ___.                 .__.__   
_/  |_|  |__  __ __  _____\_ |__   ____ _____  |__|  |  
\   __\  |  \|  |  \/     \| __ \ /    \\__  \ |  |  |  
 |  | |   Y  \  |  /  Y Y  \ \_\ \   |  \/ __ \|  |  |__
 |__| |___|  /____/|__|_|  /___  /___|  (____  /__|____/
           \/            \/    \/     \/     \/         
*/
.gig-thumbnail-recommendation {
    display: flex;
    flex-direction: column;
    /* max-width: 500px; */
    height: 100%;
    position: relative;
    /* margin: 15px 0; */
    text-align: center;
    justify-content: center;
    border-radius: 1em;
    background: white;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s;
}

.gig-thumbnail-recommendation:hover {
    transform: scale(1.03);
}

.recommended-section-link {
    text-decoration: none;
    color: inherit;
    padding: 0;
    width: 100%;
    /* margin: 10px; */
    background: none;
}

.gig-thumbnail-img {
    /* width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 20px; */
    width: 100%;
    aspect-ratio: 16/11;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.gig-thumbnail-recommendation h5 {
    color: black;
    margin: 0.7em;
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    padding: 0.5em;
    flex: 1;
}

.gig-thumbnail-info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid gainsboro;
    padding: 0.7em 1em;
}

.gig-thumbnail-booked-label {
    position: absolute;
    top: 0;

    right: 0;
    background: var(--blue-default);
    color: whitesmoke;
    padding: 0.1em 1em;
}
.gig-thumbnail-pending {
    background: var(--yellow);
}
.gig-thumbnail-completed {
    background: var(--green);
}

.gig-thumbnail-rating-section,
.gig-thumbnail-user-section {
    display: flex;
    align-items: center;
}

.gig-rating-p {
    font-size: 1.2em;
    margin-right: 0.4em;
}

.gig-consultant-pic {
    width: 2.4em;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 10px;
}

.rating-div {
    width: 30%;
}

.gig-price {
    font-size: 1.1em;
}

.recommended-section hr {
    width: 40%;
    margin: 50px auto;
}

.gig-thumbnail-other-info {
    display: flex;
    justify-content: space-between;
    margin: 0.7em 1.5em;
}

.view-count {
    margin: 0 10px 0 0;
}

/* .gig-thumbnail-other-info p {
    color: #000;
    text-align: right;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
} */

.empty-recommendation-container {
    width: 100%;
    /* text-align: center; */
}

@media screen and (max-width: 769px) {
    .recommended-section-link:nth-child(n+3) {
        margin-top: 1.5em;
    }
}

@media screen and (max-width: 426px) {
    .recommended-section-link:not(:first-child) {
        margin-top: 1.5em;
    }

    .gig-thumbnail-recommendation {
        font-size: 0.9em;
    }
    .gig-thumbnail-rating-section {
        position: absolute;
        top: 0px;
        left: 0;
        padding: 0.1em 0.2em 0.1em 1em;
        box-shadow: var(--std-shadow);
        color: white;
        background: #0952b3;
    }
}

@media screen and (max-width: 800px) {
    /* .recommended-gig-header {
        margin-left: 2.5em;
    } */
}