.gig-setup-get-available-time {
    padding-bottom: 2em;
    border-bottom: 1px solid gray;
}

.from-label,
.to-label {
    font-size: 0.8em;
    margin-bottom: 10px !important;
    margin-top: 10px !important;

}

.gig-setup-get-available-time input {
    outline: none;
    border: none;
}

.MuiStack-root.css-4jnixx-MuiStack-root {
    flex-direction: column;
}

.gig-setup-get-available-date {
    margin: 1em 0;
}

.gig-setup-get-available-date p {
    margin: 1em 0;
}

.days-selector {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1em;
    padding: 0.6em 1em;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 0.8em;
}

.days-selector button {
    box-sizing: content-box;
    text-overflow: ellipsis;
    background: transparent;
    padding: 0.4rem;
    margin: 0;
    font-size: 0.9rem;
    transition: 0.2s;
}

.days-selector button:not(.gig-day-not-selectable):hover {
    background: #0a63d9 !important;
    color: #ebebeb;
}

.gig-setup-day-select {
    background: #0952b3 !important;
    color: #ebebeb;
}

.gig-setup-day-select.init-day {
    border-radius: 0.7rem 0 0 0.7rem;
}

.gig-setup-day-select.final-day {
    border-radius: 0 0.7rem .7rem 0;
}


/* @container (max-width: 975px) { */
    .days-selector-mobile button {
        font-size: 0rem;
    }

    .days-selector-mobile button::first-letter {
        font-size: 1.2rem;
    }
/* } */

/* @container (max-width: 550px) { */
    .days-selector-mobile-small button {
        font-size: 0rem;
    }

    .days-selector-mobile-small button::first-letter {
        font-size: 1rem;
    }
/* } */