.session-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    /* height: 600px; */
    /* background-color: gray; */
    padding: 3em var(--margin);
    max-width: 1300px;
    margin: auto;
    gap: 2em;
}

.session-section-left {
    width: 50%;
}

.session-header {
    font-size: 1.2em;
    margin-top: 10px;
}
.session-header a {
    color: #0952b3;
}

.session-status-title {
    width: 100%;
}


/* SESSION LABEL */
:root {
    --pending-color  : rgb(170, 170, 84);  --pending-color-bg  : rgba(255, 255, 0, 0.1);
    --confirmed-color: green;              --confirmed-color-bg: rgba(0, 128, 0, 0.2);
    --ongoing-color  : orange;             --ongoing-color-bg  : rgba(255, 166, 0, 0.1);
    --completed-color: #0952b3;            --completed-color-bg: rgba(9, 83, 179, 0.1);
    --rejected-color : rgb(221, 41, 41);   --rejected-color-bg : rgba(255, 0, 0, 0.1);
}
.session-status-wrapper {
    display: flex;
}
.session-status-label {
    border-width: 1px;
    border-style: solid;
    width: fit-content;
    padding: 0.2em 0.7em;
    border-radius: 2em;
}
.session-status-label::before {
    content: '';
    display: inline-block;
    width: 0.5em;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    margin-right: 0.5em;
}
/* COLOR SETTINGS */
.session-status-pending   { color: var(--pending-color  ); background-color: var(--pending-color-bg  ); }
.session-status-confirmed { color: var(--confirmed-color); background-color: var(--confirmed-color-bg); }
.session-status-ongoing   { color: var(--ongoing-color  ); background-color: var(--ongoing-color-bg  ); }
.session-status-completed { color: var(--completed-color); background-color: var(--completed-color-bg); }
.session-status-rejected  { color: var(--rejected-color ); background-color: var(--rejected-color-bg ); }

.session-status-pending::before   { background: var(--pending-color);}
.session-status-confirmed::before { background: var(--confirmed-color);}
.session-status-ongoing::before   { background: var(--ongoing-color);}
.session-status-completed::before { background: var(--completed-color);}
.session-status-rejected::before  { background: var(--rejected-color);}

.session-status-join .countdown-timer-container {
    width: fit-content;
}

.session-thumbnail-img {
    width: 100%;
    aspect-ratio: 16/9;
    /* border: 1px solid red; */
    background-color: gray;
}

.session-time-container {
    margin-top: 15px;
    font-size: 0.9em;
}

.session-section-right {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.session-section-right-sub-div {
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    height: fit-content;
    padding: 1em;
    border-radius: 10px;
    margin: 20px 0;
}

.session-section-right-sub-div h2 {
    color: #004FAD;
    font-size: 1em;
    width: 100px;
    font-weight: normal;
}

.session-section-right-sub-div-div {
    display: flex;
    align-items: center;
    font-size: 1em;
    padding: 0.5em 0;
    margin: 0.6em 0;
    /* border-bottom: 1px solid gainsboro; */
    font-weight: normal;
}

.session-section-right-sub-div-div a {
    color: black;
    position: relative;
}
.session-section-right-sub-div-div a::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    height: 1px;
    width: 0;
    left: 50%;
    transform: translate(-50%);
    background: black;
    transition: 0.4s ease-out;
}
.session-section-right-sub-div-div a:hover::after {
    width: 90%
}

.session-section-right-sub-div-div:last-child {
    border-bottom: none;
}

.session-button-div {
    margin-top: 20px;
}

.session-button-div button {
    /* border: 1px solid black; */
    cursor: pointer;
    border-radius: 5px;
    padding: 0.4em 0.8em;
    margin-right: 10px;
    color: white;
}

.discussion-user-input {
    padding: 1em 0;
}

.textfield {
    width: 100%;
    padding: 0.6em 1em;
    border-radius: 8px;
    border: 1px solid gray;
}

.discussion-submit-button {
    margin-left: 5px;
    padding: 0.6em 0.8em;
    font-size: 0.9em;
    background-color: #004FAD;
    color: white;
    border-radius: 8px;
}

.session-reschedule {
    background-color: darkgoldenrod;
}

.session-approve {
    background-color: green;
}

.session-cancel {
    background-color: darkred;
}

.discussion-header {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.discussion-section {
    /* margin-top: 20px; */
    padding: 2em 1em 1em 1em;
}

.each-discussion-user {
    display: flex;
    margin-bottom: 10px;
}

.each-comment-section {
    display: flex;
}

.each-discussion,
.each-discussion-success,
.each-discussion-info,
.each-discussion-warning {
    margin-bottom: 20px;
    border-bottom: 1px solid gainsboro;
}

.each-discussion-success,
.each-discussion-info,
.each-discussion-warning {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    color: green;
}
.each-discussion-success .each-discussion-comment,
.each-discussion-info .each-discussion-comment,
.each-discussion-warning .each-discussion-comment{
    display: flex;
    align-items: center;
    gap: 0.7em;
}

.each-discussion svg {
    font-size: 1.5em;
}

.each-discussion-info {
    color: darkblue;
}

.each-discussion-warning {
    color: brown;
}

.each-discussion-name {
    font-size: 0.9em;
    font-weight: 600;
    color: black;
}

.each-discussion-date {
    font-size: 0.7em;
    color: gray;
    text-align: start;
}

.each-discussion-parent {
    display: flex;
    gap: 0.5em;
    text-align: center;
}

.each-discussion-comment {
    font-size: 0.9em;
    margin-bottom: 14px;
}

.profile-img {
    width: 40px;
    height: 40px;
    background-color: gray;
    border-radius: 100%;
    margin-right: 10px;
    object-fit: cover;
}

.session-status-join {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    margin: 10px 0;

}

.session-join-button {
    padding: 0.6em 0.8em;
    font-size: 0.9em;
    background-color: #004FAD;
    color: white;
    border-radius: 8px;
    height: fit-content;
    transition: 0.3s;
}
.session-join-button:hover {
    transform: scale(1.03);
}
.session-join-button:disabled {
    background-color: gray;
}
.session-join-button:disabled:hover {
    transform: none;
}



@media screen and (max-width: 769px) {
    .session-container {
        display: block;
    }

    .session-section-left {
        width: 100%;
    }

    .session-section-right {
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }
}

@media screen and (max-width: 500px) {
    .session-time-container {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        margin: 10px 0;
    }

    .session-time-container>div {
        width: 100%;
    }

    .time-picker-container-session {
        margin: 0;
    }

    .session-container {
        padding: 1em;
    }
}