* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* color: #002147; */
    --background: white;
}

#root:has(.landing-container) > div:not(.landing-container, .landing-filler) {
    position: relative;
    z-index: 1;
}
.navbar-container {
    width: 100%;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    align-items: center;
    background-color: #0952B3;
    justify-content: space-between;
    top: 0;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .2);
    z-index: 2;
    padding: 0 var(--margin);
}

.company-container {
    display: flex;
    flex-direction: row;
}

.company-logo {
    width: 14em;
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    cursor: pointer;
}

.navbar-list {
    display: flex;
    font-size: 1.2em;
}

.navbar-each-list {
    list-style: none;
}

.navbar-each-list a {
    color: whitesmoke;
    text-decoration: none;
}

.navbar-each-list a:hover {
    color: #002147;
}

.navbar-each-list:not(:first-child) {
    margin-left: 2em;
}

.navbar-login,
.navbar-button {
    margin-right: 2em;
    background-color: whitesmoke;
    padding: 0.8em 1.5em;
    border-radius: 15px;
    font-size: 1em;
    font-weight: bold;
    color: #002147;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.navbar-button {
    border: 1px solid white;
    background-color: #004fab;
    color: white;
}

.navbar-button:hover {
    background-color: whitesmoke;
    color: #002147;
    box-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 2px #002147, 0 0 2px #002147,
        0 0 3px #002147, 0 0 20px #002147;
}

.navbar-login:hover,
.landing-button:hover {
    /* border: 1px solid #002147; */
    background-color: #002147;
    color: whitesmoke;
    box-shadow: 0 0 1px #002147, 0 0 1px #002147, 0 0 2px #002147, 0 0 3px #002147,
        0 0 5px #002147, 0 0 20px #002147;
}

.navbar-span {
    color: whitesmoke;
    margin-top: 5px;
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: 500;
}

.landing-toggle {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-right: 10px;
}

.landing-container {
    /* position: sticky;
    top: 0px; */
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: #0952B3; */
    /* background: rgb(33, 150, 243); */
    background: linear-gradient(354deg, rgba(33, 150, 243, 1) 0%, rgba(9, 82, 179, 1) 84%);
    width: 100%;
    height: calc(75vh + 100px);
    padding-top: 100px
    /* background: #0952B3; */
    /* border-radius: 0 0 6em 6em; */
    /* animation: landingBg 2s linear 0s infinite alternate; */
    /* transition: 0.1ms; */
    /* animation: colorChange 5s infinite ease-in alternate; */
    /* z-index: -1; */
}

.landing-filler {
    height: 75vh;
    width: 100%;
}


@keyframes colorChange {
    0% {
      background-position: 0% 50%;
      background: hsl(214, 90%, 40%);
    }
    50% {
      background-position: 100% 50%;
      background: hsl(214, 90%, 37%);
    }
    100% {
        background-position: 0% 50%;
        background: hsl(214, 90%, 35%);
    }
}

.landing-img {
    width: 20%;
}

.landing-header {
    font-size: 3.4em;
    color: whitesmoke;
    animation: slideIn 1.5s ease-out forwards;
}

.landing-container>div {
    width: 100%;
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.landing-button {
    margin-top: 2em;
    background-color: whitesmoke;
    padding: 1em 2em;
    border-radius: 15px;
    font-size: 1em;
    font-weight: bold;
    color: #002147;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Sort Section */

.sort-container {
    display: flex;
    flex-direction: column;
    padding: var(--margin);
    padding-top: 4em;
    background: var(--background);
}

.sort-container h1,
.sort-container p {
    text-align: center;
}

.sort-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    height: fit-content;
    gap: 1em;
    /* border: 1px solid black; */
}

.sort-select {
    border: 0.5px solid black;
    padding: 0.4em;
    font-size: 1.1em;
    border-radius: 5px;
    color: black;
    max-width: 7em;
    width: 100%;
    transition: 0.3s;;
}

.sort-select:hover {
    /* border: 1px solid #0952B3;
    color: #0952B3; */
    transform: scale(1.03);
}

.gig-sort-button {
    padding: 0.7em 2em;
    border-radius: 5px;
    border: none;
    background-color: #0952B3;
    color: white;
    font-size: 1em;
    transition: 0.3s;
}

.gig-sort-button:hover {
    background-color: #004fab;
    transform: scale(1.03);
}

.scroll-to-top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 1.7em;
    right: 2em;
    border-radius: 10px;
    background: #002147;
    width: 40px;
    height: 40px;
    color: white;
    transition: 0.3s;
    z-index: 2;
}

.scroll-to-top:hover {
    color: #002147;
    background-color: wheat;
    box-shadow: 0 0 4px 1px #002147;

}

.gigs-container {
    /* display: flex; */
    /* justify-content: space-evenly;
    width: 100%; */
    padding: 4em 0;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-row-gap: 1.3em;
    grid-column-gap: 1.3em;
}

.gig-card {
    /* padding: 1em; */
    width: 460px;
    border-radius: 15px;
    -webkit-box-shadow: -2px 3px 60px 0px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -2px 3px 60px 0px rgba(0, 0, 0, 0.26);
    box-shadow: -2px 3px 60px 0px rgba(0, 0, 0, 0.26);
}

.gig-img {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 300px;
    object-fit: cover;
}

.gig-profile-img {
    border-radius: 100%;
    width: 50px;
}

.gig-profile-name {
    margin-left: 10px;
}

.gig-header {
    color: black;
    margin: 0.2em 0 0 0.7em;
    font-size: 1.2em;
    font-weight: 700;
}

.gig-user-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid gainsboro;
    padding: 1em;
}

.gig-user-info,
.gig-rating {
    display: flex;
    align-items: center;
}

.gig-rating-p {
    font-size: 1.2em;
    margin-right: 0.4em;
}

.gig-description {
    margin: 0.5em 0 1em 0.5em;
    color: black;
}

.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5px);
    z-index: 2;
}

.registration-container-wrapper {
    display: flex;
    justify-content: center;
}

.registration-container {
    display: flex;
    flex-direction: column;
    height: 80vh;
    position: relative;
    max-width: 800px;
    margin: 1em var(--margin);
    width: 100%;
    /* margin-top: -40px; */
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: -2px 3px 60px 0px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: -2px 3px 60px 0px rgba(0, 0, 0, 0.26);
    box-shadow: -2px 3px 60px 0px rgba(0, 0, 0, 0.26);
    z-index: 3;
}

.registration-content {
    display: flex;
    flex-direction: column;
    padding: var(--margin);
    color: #002147;
    height: 100%;
}

.registration-header {
    color: #002147;
}

.registration-button {
    padding: 0.8em 2em;
    border: none;
    background-color: lightcoral;
    border-radius: 10px;
    font-size: 1em;
}

.step-img-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form-container {
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    margin: 1em 0;
    padding: 1em 0;
    flex: 1;
    /* border: 1px solid black; */
    /* height: 300px; */
}

.progress-container {
    width: 100%;
    background-color: #002147;
    margin: 20px 0;
}

.form-progress {
    -webkit-appearance: none;
    appearance: none;
    margin: 1em 0;
    height: 20px;
    width: 100%;
}

.form-progress::-webkit-progress-bar {
    background: #ccc;
    border-radius: 1em;
}

.form-progress::-webkit-progress-value {
    border-radius: 1em;
    transition: width 0.5s;
    background: #0952B3;
}

.step-header {
    color: #004fab;
}

.step-input {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid #999;
    transition: 0.2s all linear;
}

.step-input:checked {
    border: 5px solid #002147;
}


.step-label {
    font-size: 1.2em;
    color: #002147;
    margin-left: 1em;
}

.step-button {
    width: fit-content;
    padding: 0.3em 0.5em;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    color: white;
    background-color: #002147;
    cursor: pointer;
}

.step-img {
    width: 30px;
    display: inline-block;
    cursor: pointer;
}

.step-img:nth-of-type(2) {
    margin-left: 10px;
}

/* TESTIMONIAL SECTION */

.testimonial-container {
    background-color: whitesmoke;
    overflow: hidden;
    margin-top: 6em;
    padding: 4em;
    height: 100vh;
}

.testimonial-container p {
    margin-left: 1.2em;
}

.testimonial-section {
    display: flex;
    margin-top: 4em;
    margin-left: 5em;
}

.testimonial-slideshow {
    position: relative;
    width: 40%;
    margin-left: 1.4em;
}

.testimonial-img {
    position: relative;
    width: 300px;
    height: 300px;
    z-index: 1;
}

.testimonial-svg {
    position: absolute;
    top: 55%;
    /* overflow: hidden; */
    left: -200px;
    transform: translate(0, -50%);
    z-index: 0;
    height: auto;
    width: 100%;
}

.testimonial-slideshow-p {
    text-align: start !important;
    margin-top: 0.8em;
    margin-left: 0.2em;
    font-size: 1.4em;
    font-weight: 500;
    z-index: 1;
}

.testimonial-article {
    width: 70%;
    margin-top: 3em;
    font-size: 1.1em;
    color: #002147;
}

/* FAQ Section */

.faq-container {
    width: 100%;
    display: flex;
    position: relative;
    height: fit-content;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    background: var(--background);
    padding: 4em var(--margin) 8em var(--margin);
}

.faq-mini-container {
    display: flex;
    justify-content: space-between;
}

.faq-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-left: 3em;
    margin-top: 1em; */
}

.img-wrapper {
    /* border: 1px solid red; */
    background-color: red;
    position: absolute;
    height: 150%;
    object-fit: cover;
    overflow: hidden;
    /* z-index: -1; */
    width: 50%;
    opacity: 13%;
    right: 0;
}

.img-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
}

.faq-dot-svg {
    /* position: absolute; */
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.faq-section h3 {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.each-faq {
    margin-top: 2em;
    border-bottom: 1px solid black;
    padding: 0 0 1.5em 0;
}

.each-faq p {
    margin-top: 1em;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition-timing-function: cubic-bezier(.97, .13, .49, 1);
    transition: 0.5s;
}

.each-faq .faq-open {
    opacity: 100%;
    max-height: 200px;
}

/* Commitment Section */

.commitment-container {
    margin-bottom: -5em;
    padding: 5em var(--margin) 4em var(--margin);
    font-size: 0.9em;
    width: 100%;
    height: fit-content;
    background-color: #0952B3;
    /* border-radius: 6em 6em 0 0; */
}
.commitment-container::after{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
}

.commitment-container h1,
.commitment-container h4,
.commitment-container span {
    color: whitesmoke;
}

.commitment-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
    margin-bottom: 2em;
    gap: 50px;
    flex-direction: row-reverse;
}

.commitment-img {
    max-width: 23em;
    width: 100%;
    height: auto;
}

.commitment-article {
    /* padding-right: 6em; */
    max-width: 40em;
    /* width: 60%; */
    /* font-size: 1.1em; */
    color: #9daac7;
    ;
}

.commitment-article-heading {
    font-size: 2em;
    margin-bottom: 1em;
}

.commitment-div {
    margin-top: 1em;
}

/* Footer Section */

.footer-spacer {
    min-height: 10px;
    width: 100%;
}

.footer-content-level-1 {
    font-size: 1.5em;
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: .5em;
    color: whitesmoke;
}

.footer-content-level-2 {
    margin-bottom: 0.1em;
    font-size: .8em;
    color: whitesmoke;
}

.footer-content-level-3 {
    padding-top: 0.25rem;
    margin-left: 10px;
    font-size: 0.7em;
}


.footer-content-level-4 {
    padding-bottom: 0.3em;
    font-size: 0.5em;
}

.footer-wrapper {
    padding-bottom: .3em;
    flex-direction: row;
    height: max-content;

}

.footer-links>.wrapper {
    max-width: 75%;
    display: flex;
    flex-direction: column;
}

.footer-links {
    padding-left: 3em;
    display: flex;
    width: 100%;
    height: 350px;
    flex-flow: column wrap;
}


.footer {
    margin-top: auto;
    background-color: #0952B3;
    color: #ffffff;
    font-family: 'Inter';
}


.hero-footer {
    max-height: fit-content;
    padding-left: 5em;
    padding-right: 5em;
    color: inherit;
    background-color: inherit;
    border-top: 1px solid #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.hero-footer-left {
    font-size: x-large;
    display: flex;
    align-items: center;
    gap: 1em;
}

.hero-footer-left>h2 {
    color: whitesmoke;
}

.hero-footer-left>span {
    font-size: small;
    font-weight: 300;
    color: whitesmoke;
}

.hero-footer-right>ul {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
}

.hero-footer-right>ul>li {
    margin-left: 0.5em;
    margin-top: .75em;
    margin-bottom: .5em;
}

@media screen and (max-width: 1025px) {
    .gig-card {
        width: 300px;
    }

    .gig-img {
        height: 200px;
    }

    .company-logo {
        width: 12em;
    }

    .navbar-button,
    .navbar-login {
        padding: 0.6em 1em;
        margin-right: 0.8em;
    }

    .testimonial-article {
        margin-top: 1.8em;
        margin-left: 1em;
    }

    .commitment-img {
        width: 50%;
    }

    .commitment-article {
        padding-right: 0.2em;
    }

    .footer-content-level-1 {
        font-size: 1em;
    }

    .footer-content-level-2 {
        font-size: .675em;
        text-align: start;
    }


    .footer-content-level-3 {
        font-size: 0.575em;
        ;
    }

    .hero-footer-left {
        font-size: medium;
    }

    .hero-footer-left>span {
        display: none;
    }

    .footer-links {
        height: 300px;
    }

    .footer-links {
        flex-flow: column wrap;
    }

}

@media screen and (max-width: 775px) {
    .company-logo {
        width: 10em;
    }

    .navbar-container {
        width: 100%;
        padding: 10px;
    }

    .navbar-each-list:not(:first-child) {
        margin-left: 0.5em;
    }

    .navbar-each-list a {
        color: whitesmoke;
        text-decoration: none;
        font-size: 0.84em;
    }

    .navbar-button,
    .navbar-login {
        padding: 0.8em;
        margin-right: 1em;
        font-size: 0.8em;
    }

    .gigs-container {
        flex-wrap: wrap;
    }

    .gig-card {
        width: 230px;
        margin-left: 10px;
        margin-top: 10px;
    }

    /* .landing-container {
        border-bottom-right-radius: 15%;
        border-bottom-left-radius: 15%;
    } */

    .landing-header {
        font-size: 3em;
    }

    .testimonial-img {
        width: 200px;
        height: 200px;
    }

    .testimonial-article {
        margin-top: 0;
    }

    .footer {
        height: auto;
    }

    .footer-content-level-1 {
        width: max-content;

    }

    .footer-links {
        height: fit-content;
        width: 50%;
        margin: auto;
        padding: 0;
    }

    .footer-wrapper {

        text-align: center;
    }

    .footer-wrapper>.footer-content-level-1 {
        margin: 0 auto;
    }
}

@media screen and (max-width:426px) {
    .navbar-container {
        padding: 10px;
    }

    .navbar-list {
        display: none;
    }

    section {
        grid-area: button;
    }

    .navbar-login,
    .navbar-button {
        padding: 0.8em 1.4em;
    }

    .navbar-each-list:first-of-type {
        margin-left: 0.6em;
    }

    .gigs-container {
        flex-wrap: nowrap;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
/* 
    .landing-container {
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;
    } */

    .landing-header {
        font-size: 2.5em;
    }

    .sort-container h1 {
        margin: 0 10px;
    }

    .gigs-container,
    .sort-selection {
        flex-wrap: wrap;
    }

    .gigs-container {
        margin-top: 2em;
    }

    .gig-card {
        width: 180px;
        margin-top: 10px;
    }

    .gig-card:nth-child(n+5) {
        display: none;
    }

    .gig-img {
        width: 100%;
        height: 150px;
    }

    .gig-header {
        font-size: 1em;
        margin-left: 0;
        text-align: start !important;
    }

    .gig-description {
        font-size: 0.9em;
        text-align: start !important;
        margin-left: 0;
        margin-bottom: 0;
    }

    .testimonial-container {
        padding: 2em 0;
        height: 380px;
    }

    .testimonial-slideshow {
        margin-left: 2em;
    }

    .testimonial-img {
        width: 120px;
        height: 120px;
    }

    .testimonial-slideshow-p {
        font-size: 1.18em;
        margin-left: 0;
        margin-top: 0.2em;
    }

    .testimonial-article {
        font-size: 0.88em;
        margin-right: 1em;
        margin-left: 0;
    }
/* 
    .commitment-container {
        border-top-right-radius: 60px;
        border-top-left-radius: 60px;
    } */

    .commitment-section {
        flex-direction: column;
    }

    .commitment-article {
        width: 100%;
    }

    .commitment-img {
        width: 100%;
        height: 280px;
    }
}

@media screen and (max-width: 376px) {
    .company-logo {
        width: 8em;
    }

    .navbar-each-list {
        font-size: 0.9em;
    }

    .testimonial-slideshow {
        margin-left: 1em;
    }

    .testimonial-slideshow-p {
        font-size: 1em;
    }
}

@media screen and (max-width: 321px) {
    .landing-header {
        font-size: 2em;
    }

    .gigs-container {
        padding: 0;
    }

    .faq-container {
        padding: 1em 2em;
    }

    .faq-section h3 {
        font-size: 1em;
    }

    .each-faq {
        padding: 0 0 1em 0;
    }

    .sort-container h1 {
        font-size: 1.2em;
        text-align: start;
        margin: 0;
    }

    .sort-container p {
        font-size: 0.8em;
        text-align: start;
    }

    .scroll-to-top {
        right: 1.2em;
    }

    .faq-container h1 {
        margin-left: 0;
    }

    .navbar-button,
    .navbar-login {
        font-size: 0.8em;
        padding: 0.7em;
    }

    .navbar-each-list {
        font-size: 0.77em;
    }
}