.consultant-session-main-container {
    display: flex;
    height: calc(100vh - 80px);
    justify-content: space-between;
    margin: 0 auto;
    /* max-width: 1400px; */
}

.consultant-session-user-container {
    display: block;
    /* flex: 1; */
    /* min-width: 200px;
    max-width: 300px; */
    padding-top: 2em;
    /* margin-right: 2em; */
    overflow: auto;
}

.consultant-session-user-container::before {
    content: 'Users';
    font-size: 1.5em;
    padding: 1rem;
}

.consultant-session-users {
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 0.2em 1em;
    transition: 0.3s;
    padding: 1em;
    border-radius: 1em;
    cursor: pointer;
}

.consultant-session-users.active-session-user {
    background: var(--gray-background);
}

.consultant-session-user-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    gap: 2em;
    padding: 1em 2em;
    background: whitesmoke;
    box-shadow: var(--std-shadow);
    width: 100%;
    z-index: 1000;
}
.consultant-session-user-header img {
    width: 50px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
}

.consultant-session-container-right {
    flex: 1;
    overflow: auto;
    background: var(--gray-background);
}

.consultant-session-users:hover {
    background: #dbdee1;
}

.consultant-session-users img {
    width: 3em;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.consultant-session-data-container {
    flex: 1;
    display: flex;
    /* justify-content: right; */
    flex-direction: column;
    /* height: 100%; */
    overflow: auto;
    gap: 5em;
    padding: 3em;
}

.consultant-session-status-p {
    font-size: 1.4em;
    margin: 2em 0 1em 0;
    font-weight: 100;
}

.consultant-session-status-container {
    /* display: flex;
    flex-wrap: wrap;
    gap: 20px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-row-gap: 1.3em;
    grid-column-gap: 1.3em;
    margin: 0 auto;
    background: #f9f9f9;
    padding: 2em;
    border-radius: 2em;
    box-shadow: var(--std-shadow);
}

.gig-wrapper > div {
    margin: 0 0 1em 0;
}
.gig-wrapper h2 {
    /* width: 300px; */
    padding-bottom: 10px;
}

.consultant-session-empty-selection {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1em;
    max-width: 700px;
    width: 100%;
    margin: auto;
    text-align: left;
    justify-content: left;
}

.consultant-session-empty-selection img {
    width: 100%;
}

.consultant-session-empty-selection h3 {
    font-weight: 400;
}

@media (max-width: 768px) {
    .consultant-session-user-container {
        margin: 0;
    }
    .consultant-session-users .consultant-session-username {
        display: none;
    }
    .consultant-session-users {
        margin: 0.2em auto;
        width: fit-content;
    }
    .consultant-session-data-container {
        padding: 0 1em 2em 1em;
    }
    .consultant-session-empty-selection {
        justify-content: flex-start;
        align-items: normal;
        padding-top: 2em;
    }
}

@media (max-width: 500px) {
    .consultant-session-status-p {
        font-size: 1.2em;
    }
    .consultant-session-user-container::before {
        font-size: 1.2em;
    }
    .consultant-session-users {
        padding: 0.5em;
    }
    .consultant-session-empty-selection h3 {
        font-size: 0.9em;
    }
}

@media (max-width: 425px) {
    .consultant-session-status-container {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        font-size: 0.85em;
        padding: 2em 1em;
    }
}