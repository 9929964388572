* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'roboto';
  scroll-behavior: smooth;
}

:root {
  --margin: 3.5rem;
}

@media (max-width: 525px) {
  :root {
    --margin: 2rem;
  }
}

body, #root {
  min-width: 100%;
  min-height: 100vh;
}

@font-face {
  font-family: 'roboto';
  src: url('./assets/font/Roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}


button {
  background-color: #ffffff;
  color: #333333;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

select, option { cursor: pointer; }

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

img {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.image-preloader {
  position: relative;
}
.image-preloader::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: inherit;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* TOASTIFY VARIABLES */
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #fff;
  --toastify-color-info: #3498db;
  /* --toastify-color-success: hsl(122, 99%, 37%); */
  --toastify-color-success: hsl(122, 100%, 34%);
  --toastify-color-warning: hsl(48, 100%, 50%);
  --toastify-color-error: #e74c3c;
}


/* ONLINE CLIENT STYLING */
.active-client, .inactive-client {
  position: relative;
}
.active-client::after, .inactive-client::after {
  position: absolute;
  right: 7%;
  bottom: 7%;
  content: '';
  display: block;
  width: 0.5em;
  height: 0.5em;
  background: var(--green);
  border: 2px solid #d3e8d9;
  border-radius: 50%;
}
.inactive-client::after{
  background: var(--yellow);
  border: 2px solid #ece8d8;
}