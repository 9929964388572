/* CSS for prof.css */

.ent-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 30px;
  background-color: #F9F9F9;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  justify-content: center;
  width: 100%;
}


.GigThumbnail {
  width: 100%;
  height: auto;
  aspect-ratio: 16/11;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 8px #1b1b1b1a;

}

.GigThumbnail h2 {
  font-size: 1.6em;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.GigThumbnail .gig-thumbnail-right {
  width: 100%;
  display: flex;
  justify-content: center;
}

.GigThumbnail .gig-thumbnail-right .edit-link {
  display: flex;
  justify-content: center;
}

.GigThumbnail .gig-thumbnail-right .edit-link button {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 0;
  background-color: #0952b3;

}

.GigThumbnail .gig-thumbnail-left>span>span {
  height: 0;
}

.GigThumbnail .gig-thumbnail-left>span {
  padding: 0;
}

.GigThumbnail img {
  padding: 10px;
  border-radius: inherit;
  height: auto;
}

.GigThumbnail .MuiRating-root * {
  padding: 0 !important;
}

.profile-image {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.profile-image img {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  /* margin-top: -50%; */
}

.upload-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.username {
  margin-top: 20px;
  display: flex;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
}

.joined-active-recommended {
  margin: 1em;
  font-size: 0.8em;
  text-align: center;
}

.ent-container1 .rating {
  text-align: center;
  font-size: 0.8em;
  margin: 1.3em;
}

.description h3 {
  margin-bottom: 10px;
}

.description p {
  font-weight: 300;
  font-size: 16px;
  align-self: flex-start;
  background: white;
  width: 100%;
  padding: 1em;
  border: 1px solid rgba(128, 128, 128, 0.151);
}

.desc {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.desc-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 5px #1b1b1b24;
  align-items: center;
  justify-content: center;
}

.desc img {
  height: 17px;
  width: 17px;
}

.recommendations {
  margin-top: 20px;
  text-align: left;
  width: 400px;
}

.recommendations h3 {
  margin-bottom: 10px;
}

.recommendation-container {
  padding: 20px 0;

}

.get-in-touch {
  padding: 0.5em 1em;
  background-color: #0857C0;
  color: #fff;
  border: none;
  border-radius: 0.8em;
  cursor: pointer;
  width: fit-content;
  margin: 1.5em auto;
  /* position: absolute; */
  /* top: 70px;
  right: 20px; */
}

.gigs-column {
  box-shadow: gray 1px 1px 1px 1px;
  width: 300px;
  height: auto;
  margin: 10px;
  font-size: 50%;
  border-radius: 1em;
  color: black;
  text-decoration: none;
}

/* .post-form {
  width: 100%;
  height: 100%;
} */

.post-form button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 22px;
  font-weight: 400;
}

.post {
  margin-left: 100%;
  padding: 20px;
  background-color: #fff;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.cover-image img {
  width: 100%;
  aspect-ratio: 16/9;
}

.members h3,
.review-section h3,
.invitation h3 {
  margin: 1em 0 0 0;
  color: #000;
  width: 100%;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.invitation h3 {
  margin: 1em 0 2em 0;
  width: fit-content;
}

.review-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.invitation {
  align-items: flex-start !important;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 25em;
  z-index: 1001;
}

.invitation>div {
  width: 100%;
  padding: 1.6em 3em;
}

.invitation p {
  margin: 1.2em;
  margin-top: 1.8em;
  font-size: 0.7em;
}

.invitation .consultant-invitation-section {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.invitation .consultant-email {
  outline: none;
  padding: 0.2em 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
  flex: 1;
  transition: var(--std-delta-time);
}

.invitation .consultant-email:hover,
.invitation .consultant-email:focus {
  transform: scale(1.03);
}

.invitation .invite-btn {
  background: var(--blue-default);
  color: var(--gray-background);
  transition: var(--std-delta-time);
  border-radius: 0.3em;
}

.invitation .invite-btn:hover {
  transform: scale(1.03);
}


.members-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, auto));
  width: 100%;
  /* flex-wrap: wrap; */
  gap: 10px;
  padding: 0px;

  border-radius: 10px;
  margin: 20px 0;
}

.members-wrapper .member {
  /* vertical-align: center; */
  text-align: center;
  /* border: 1px solid gray; */
  /* border-radius: 10px; */
  /* display: flex; */
  font-size: 0.8em;
  /* gap: 10px; */
  padding: 10px;
  text-decoration: none;
  color: black;
  transition: var(--std-delta-time);
}

.members-wrapper .member:hover {
  transform: scale(1.03);
}

.member .consultant-name {
  font-size: 1em;
  font-weight: 550;
}

.member img {
  width: 4em;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
}

.member.consultant-adder {
  cursor: pointer;
}

.add-consultant-img {
  width: 4em;
  margin: auto;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue-default);
  border-radius: 50%;
}

.add-consultant-img img {
  width: 50%;
  filter: invert(1) brightness(100);
}


@media(max-width: 1280px) {
  .ent-container1 {
    width: 100%;
    padding: 0 20px;
  }

  .ent-container1 .profile-image {
    width: 150px;
    height: 150px;
  }

  .ent-container1 .profile-image img {
    width: 140px;
    height: 140px;
  }

  .username {
    font-size: 1em;
  }

  .joined-active-recommended .link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .joined-active-recommended .link .ent-cons-ref {
    font-size: 1.15em;
    text-align: center;
  }

  .description .desc h3 {
    font-size: 1em;
  }

  .description p {
    text-align: left;
    align-self: flex-start;
  }

  .gigs-column {
    width: 300px !important;
  }

  .gigs-list>a {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 90%;
    min-width: 300px;

  }





}

@media(max-width: 768px) {
  .ent-container1 {
    width: 80%;
  }

  .members {
    width: 80%;
  }

  .gigs-content {
    margin: 40px 0;
    width: 100%;
  }

  .gigs-list>a {
    width: min(80%, 450px);
    justify-content: center;
    max-width: none;
  }

  .gigs-list .GigThumbnail {
    width: 100%;
  }
}